import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getRecipeAndParentOperation } from '../../operations/recipes_operations';
import MealAdvancedSearchCriteria from './meal_advanced_search_criteria';
import MealAdvancedSearchList from './meal_advanced_search_list';
import MealAdvancedSearchTopResultsPage from './meal_advanced_search_top_results_page';
import { recipesBoardsTitleMapSelector } from '../../reducers/recipes_reducer';
import PopupTitle from '../common/popup_title';
import {
  GRC_RESULTS_GROUP_LABEL,
  useMealSearch,
} from '../common/meal_search_hook';
import { allIngredientNames } from '../../services/ingredients';
import { allProgrammesTagsSelector } from '../../reducers/programmes_reducer';
import { userLocaleSelector } from '../../reducers/user_reducer';

const OWN_MEALS_GROUP_LABEL = 'Own meals';

const MealAdvancedSearchPopover = ({
  section,
  anchorEl,
  memoizedRecipesBoardIDs,
  initialSearchString,
  grcEnabled,
  onGRCMealSelected,
  onOwnMealSelected,
  onDismiss,
}) => {
  const recentlyUsedSearchCriteria = useSelector(
    (state) => state.userProfile?.recentlyUsedSearchCriteria || {},
  );

  const userDefaultLocale = useSelector(userLocaleSelector);

  const [searchCriteria, setSearchCriteria] = useState({
    ...(recentlyUsedSearchCriteria || {}),
    searchString:
      initialSearchString || recentlyUsedSearchCriteria?.searchString,
    locales: recentlyUsedSearchCriteria?.locales || [userDefaultLocale],
  });

  const [route, setRoute] = useState({ page: 'advancedCriteria' });

  const parentTitles = useSelector(recipesBoardsTitleMapSelector);

  const memoizedParentTitles = useMemo(
    () => parentTitles,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(parentTitles)],
  );

  const groupsConfig = [];
  if (memoizedRecipesBoardIDs.length > 0) {
    groupsConfig.push({
      groupLabel: OWN_MEALS_GROUP_LABEL,
      parentIDs: memoizedRecipesBoardIDs,
    });
  }
  if (grcEnabled) {
    groupsConfig.push({ groupLabel: GRC_RESULTS_GROUP_LABEL });
  }

  const [results, estimatedCount, isLoading, onLoadMore, ingredientTreeNodes] =
    useMealSearch(
      searchCriteria,
      groupsConfig,
      section,
      searchCriteria.locales,
      false,
    );

  const loadedResultsCount = results
    ? results.reduce((acc, group) => acc + group.data.length, 0)
    : 0;

  const moreAvailable = results.some(
    (groupResults) => groupResults.moreAvailable,
  );

  const ownMealResults = results.find(
    (groupResults) => groupResults.groupLabel === OWN_MEALS_GROUP_LABEL,
  );
  const smorgMealResults = results.find(
    (groupResults) => groupResults.groupLabel === GRC_RESULTS_GROUP_LABEL,
  );
  const ownMealsLoading = ownMealResults?.loading;
  const smorgMealsLoading = smorgMealResults?.loading;

  const paperRef = useRef();

  const getRecipeAndParent = useCallback(
    (itemID) =>
      getRecipeAndParentOperation(
        itemID,
        ownMealResults?.data || [],
        smorgMealResults?.data || [],
        memoizedParentTitles,
      ),
    [ownMealResults?.data, memoizedParentTitles, smorgMealResults?.data],
  );

  const onLoadMoreOwnMeals = () =>
    onLoadMore(OWN_MEALS_GROUP_LABEL, ownMealResults?.nextOffset);

  const onLoadMoreSmorgMeals = () =>
    onLoadMore(GRC_RESULTS_GROUP_LABEL, smorgMealResults?.nextOffset);

  const allTags = useSelector(allProgrammesTagsSelector);

  return (
    <Popper
      anchorEl={anchorEl}
      open
      placement="right"
      onClose={onDismiss}
      // Keep the popper above the Fab
      sx={{ zIndex: 'modal' }}
      transition>
      {({ TransitionProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            ref={paperRef}
            elevation={3}
            className="light-background"
            style={{
              width: '420px',
              outline: 'none',
              paddingBottom: 10,
            }}>
            <ClickAwayListener
              onClickAway={() => {
                /* do nothing */
              }}>
              <>
                {route.page === 'advancedCriteria' && (
                  <>
                    <PopupTitle
                      titleText="Advanced search"
                      closeButtonEnabled
                      onClickClose={onDismiss}
                    />
                    <MealAdvancedSearchCriteria
                      searchCriteria={searchCriteria}
                      onChangeSearchCriteria={setSearchCriteria}
                      totalResultsLoading={isLoading}
                      totalResults={estimatedCount}
                      loadedResultsCount={loadedResultsCount}
                      moreAvailable={moreAvailable}
                      onShowResults={() => setRoute({ page: 'topResults' })}
                      onDismiss={onDismiss}
                      allIngredients={allIngredientNames(ingredientTreeNodes)}
                      allTags={allTags}
                      localeFilterEnabled
                    />
                  </>
                )}
                {route.page === 'topResults' && (
                  <MealAdvancedSearchTopResultsPage
                    onGRCMealSelected={onGRCMealSelected}
                    onOwnMealSelected={onOwnMealSelected}
                    ownMealResults={ownMealResults}
                    ownMealsLoading={ownMealsLoading}
                    smorgMealResults={smorgMealResults}
                    smorgMealsLoading={smorgMealsLoading}
                    onChangeSearchCriteria={setSearchCriteria}
                    onGoToMyMeals={() => setRoute({ page: 'myMeals' })}
                    onGoToSmorgMeals={() => setRoute({ page: 'smorgMeals' })}
                    onBack={() => setRoute({ page: 'advancedCriteria' })}
                    getRecipeAndParent={getRecipeAndParent}
                    parentTitles={memoizedParentTitles}
                    onDismiss={onDismiss}
                  />
                )}
                {route.page === 'myMeals' && (
                  <MealAdvancedSearchList
                    groupID={route.groupID}
                    mealResults={[
                      {
                        groupID: null,
                        groupLabel: null,
                        results: ownMealResults,
                      },
                    ]}
                    mealsLoading={ownMealsLoading}
                    onMealSelected={onOwnMealSelected}
                    onLoadMore={onLoadMoreOwnMeals}
                    onBack={() => {
                      setRoute({ page: 'topResults' });
                    }}
                    onGoToGroup={(groupID) =>
                      setRoute({ page: 'myMeals', groupID })
                    }
                    parentTitles={memoizedParentTitles}
                    getRecipeAndParent={getRecipeAndParent}
                    onDismiss={onDismiss}
                  />
                )}
                {route.page === 'smorgMeals' && (
                  <MealAdvancedSearchList
                    mealResults={[
                      {
                        groupID: null,
                        groupLabel: null,
                        results: smorgMealResults,
                      },
                    ]}
                    mealsLoading={smorgMealsLoading}
                    onMealSelected={onGRCMealSelected}
                    onLoadMore={onLoadMoreSmorgMeals}
                    onBack={() => {
                      setRoute({ page: 'topResults' });
                    }}
                    getRecipeAndParent={getRecipeAndParent}
                    onDismiss={onDismiss}
                  />
                )}
              </>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

MealAdvancedSearchPopover.propTypes = {
  section: PropTypes.string.isRequired,
  anchorEl: PropTypes.element.isRequired,
  memoizedRecipesBoardIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialSearchString: PropTypes.string,
  grcEnabled: PropTypes.bool,
  onGRCMealSelected: PropTypes.func,
  onOwnMealSelected: PropTypes.func.isRequired,
  onGoToQuickSearch: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MealAdvancedSearchPopover.defaultProps = {
  grcEnabled: false,
  initialSearchString: null,
  onGRCMealSelected: () => {},
};

export default MealAdvancedSearchPopover;
