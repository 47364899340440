import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import SpaceOnboardingStepGoal from './space_onboarding_step_goal';
import {
  HEIGHT_UNITS_IMPERIAL,
  QUESTION_AGE,
  // QUESTION_CHOOSE_PROGRAMME,
  QUESTION_EXCLUDE_INGREDIENTS,
  QUESTION_EXERCISE_LEVEL,
  QUESTION_GENDER,
  QUESTION_GOAL,
  QUESTION_HEIGHT,
  QUESTION_TARGET_WEIGHT,
  QUESTION_TARGET_WEIGHT_DURATION,
  QUESTION_WEIGHT,
  WEIGHT_UNITS_IMPERIAL,
  availableQuestions,
  findAnswerFor,
  isWeightChangeTooAggressive,
  replaceAnswer,
} from '../../services/space_onboarding';
import SpaceOnboardingStepGender from './space_onboarding_step_gender';
import SpaceOnboardingStepAge from './space_onboarding_step_age';
import SpaceOnboardingStepWeight from './space_onboarding_step_weight';
import SpaceOnboardingStepHeight from './space_onboarding_step_height';
import SpaceOnboardingStepExerciseLevel from './space_onboarding_step_exercise_level';
import SpaceOnboardingStepTargetWeight from './space_onboarding_step_target_weight';
import SpaceOnboardingStepTargetWeightDuration from './space_onboarding_step_target_weight_duration';
import SpaceOnboardingStepExcludeIngredients from './space_onboarding_step_exclude_ingredients';
import { LOGIN_ROUTE } from '../../services/routes';
import { getIngredientTreeNodesOperation } from '../../operations/user_profile_operations';
import { allIngredientNames } from '../../services/ingredients';
import {
  anySharedProgrammesAvailableSelector,
  anySharedProgrammesWithPersonalisedNutritionAvailableSelector,
  userLocaleSelector,
} from '../../reducers/user_reducer';
import { signOutAction } from '../../action_creators/user_action_creators';
// import SpaceOnboardingStepChooseProgramme from './space_onboarding_step_choose_programme';
import { linkWithQueryParams } from '../../services/urls';

const SpaceOnboardingWizardContent = ({
  isSpaceWithProductizedRecipesBoards,
  onFinish,
  onBackFirstScreen,
  onChangeCurrentScreen,
  initialScreen,
  initialAnswers,
}) => {
  const [heightUnits, setHeightUnits] = useState(HEIGHT_UNITS_IMPERIAL);
  const [weightUnits, setWeightUnits] = useState(WEIGHT_UNITS_IMPERIAL);

  const anySharedProgrammesAvailable = useSelector(
    anySharedProgrammesAvailableSelector,
  );
  const anySharedProgrammesWithPersonalisedNutritionAvailable = useSelector(
    anySharedProgrammesWithPersonalisedNutritionAvailableSelector,
  );
  const allSteps =
    isSpaceWithProductizedRecipesBoards ||
    !anySharedProgrammesWithPersonalisedNutritionAvailable
      ? []
      : availableQuestions(onboardingAnswers, anySharedProgrammesAvailable);

  const initialScreenIndex = initialScreen
    ? (allSteps || []).indexOf(initialScreen)
    : -1;

  const [currentStepIndex, setCurrentStepIndex] = useState(
    initialScreenIndex >= 0 ? initialScreenIndex : 0,
  );

  const [onboardingAnswers, setOnboardingAnswers] = useState(
    initialAnswers || [],
  );

  useEffect(() => {
    if (initialAnswers) {
      setOnboardingAnswers(initialAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialAnswers)]);

  const currentStep = allSteps[currentStepIndex];
  const completionPercentage = Math.round(
    (currentStepIndex / allSteps.length) * 100,
  );

  // TODO this is incorrect, should use the space locale
  const localeForIngredientNames = useSelector(userLocaleSelector);

  const [ingredientTreeNodes, setIngredientTreeNodes] = useState([]);

  // const [chosenSharedProgrammeID, setChosenSharedProgrammeID] = useState(null);

  useEffect(() => {
    if (allSteps.includes(QUESTION_EXCLUDE_INGREDIENTS)) {
      getIngredientTreeNodesOperation(localeForIngredientNames).then(
        setIngredientTreeNodes,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(allSteps), localeForIngredientNames]);

  const answerFor = (questionID) =>
    findAnswerFor(questionID, onboardingAnswers);

  const setAnswer = (questionID, answerRaw) => {
    setOnboardingAnswers((oldAnswers) =>
      replaceAnswer(questionID, answerRaw, oldAnswers),
    );
  };

  console.log(JSON.stringify(onboardingAnswers));

  const onNext = (thisAnswerRaw) => {
    // if (currentStep === QUESTION_CHOOSE_PROGRAMME) {
    //   setChosenSharedProgrammeID(thisAnswerRaw);
    // } else {
      setAnswer(currentStep, thisAnswerRaw);
    // }
    setCurrentStepIndex(currentStepIndex + 1);
    if (onChangeCurrentScreen && currentStepIndex + 1 < allSteps.length) {
      const updatedAnswers = replaceAnswer(
        currentStep,
        thisAnswerRaw,
        onboardingAnswers,
      );
      onChangeCurrentScreen(allSteps[currentStepIndex + 1], updatedAnswers);
    }
  };

  useEffect(() => {
    if (currentStepIndex === allSteps.length) {
      onFinish(onboardingAnswers, null/*chosenSharedProgrammeID*/);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSteps.length, currentStepIndex, onFinish]);

  const onBack = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  if (currentStep === QUESTION_GOAL) {
    return (
      <SpaceOnboardingStepGoal
        // sharedProgrammeTitle={sharedProgrammeTitle}
        goal={answerFor(QUESTION_GOAL) || 'loseweight'}
        setGoal={(newGoal) => setAnswer(QUESTION_GOAL, newGoal)}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBackFirstScreen}
      />
    );
  }

  if (currentStep === QUESTION_GENDER) {
    return (
      <SpaceOnboardingStepGender
        // sharedProgrammeTitle={sharedProgrammeTitle}
        gender={answerFor(QUESTION_GENDER) || 'male'}
        setGender={(newGender) => setAnswer(QUESTION_GENDER, newGender)}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_AGE) {
    return (
      <SpaceOnboardingStepAge
        // sharedProgrammeTitle={sharedProgrammeTitle}
        age={answerFor(QUESTION_AGE) || 25}
        setAge={(newAge) => setAnswer(QUESTION_AGE, newAge)}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_WEIGHT) {
    return (
      <SpaceOnboardingStepWeight
        // sharedProgrammeTitle={sharedProgrammeTitle}
        weight={answerFor(QUESTION_WEIGHT) || 65}
        setWeight={(newWeight) => setAnswer(QUESTION_WEIGHT, newWeight)}
        weightUnits={weightUnits}
        setWeightUnits={setWeightUnits}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_HEIGHT) {
    return (
      <SpaceOnboardingStepHeight
        // sharedProgrammeTitle={sharedProgrammeTitle}
        height={answerFor(QUESTION_HEIGHT) || 175}
        setHeight={(newHeight) => setAnswer(QUESTION_HEIGHT, newHeight)}
        heightUnits={heightUnits}
        setHeightUnits={setHeightUnits}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_EXERCISE_LEVEL) {
    return (
      <SpaceOnboardingStepExerciseLevel
        // sharedProgrammeTitle={sharedProgrammeTitle}
        exerciseLevel={answerFor(QUESTION_EXERCISE_LEVEL) || '0'}
        setExerciseLevel={(newExerciseLevel) =>
          setAnswer(QUESTION_EXERCISE_LEVEL, newExerciseLevel)
        }
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_TARGET_WEIGHT) {
    return (
      <SpaceOnboardingStepTargetWeight
        // sharedProgrammeTitle={sharedProgrammeTitle}
        targetWeight={answerFor(QUESTION_TARGET_WEIGHT) || 65}
        setTargetWeight={(newWeight) =>
          setAnswer(QUESTION_TARGET_WEIGHT, newWeight)
        }
        weightUnits={weightUnits}
        setWeightUnits={setWeightUnits}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_TARGET_WEIGHT_DURATION) {
    return (
      <SpaceOnboardingStepTargetWeightDuration
        // sharedProgrammeTitle={sharedProgrammeTitle}
        targetWeightDuration={answerFor(QUESTION_TARGET_WEIGHT_DURATION) || 12}
        setTargetWeightDuration={(newDuration) =>
          setAnswer(QUESTION_TARGET_WEIGHT_DURATION, newDuration)
        }
        isWeightChangeTooAggressive={(targetWeightDurationInput) =>
          isWeightChangeTooAggressive(
            answerFor(QUESTION_WEIGHT),
            answerFor(QUESTION_TARGET_WEIGHT),
            targetWeightDurationInput,
          )
        }
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_EXCLUDE_INGREDIENTS) {
    return (
      <SpaceOnboardingStepExcludeIngredients
        // sharedProgrammeTitle={sharedProgrammeTitle}
        excludeIngredients={answerFor(QUESTION_EXCLUDE_INGREDIENTS) || []}
        setExcludeIngredients={(newExcludeIngredients) =>
          setAnswer(QUESTION_EXCLUDE_INGREDIENTS, newExcludeIngredients)
        }
        allIngredients={allIngredientNames(ingredientTreeNodes)}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }
  // if (currentStep === QUESTION_CHOOSE_PROGRAMME) {
  //   return (
  //     <SpaceOnboardingStepChooseProgramme
  //       chosenSharedProgrammeID={chosenSharedProgrammeID}
  //       setChosenSharedProgrammeID={setChosenSharedProgrammeID}
  //       completionPercentage={completionPercentage}
  //       onNext={onNext}
  //       onBack={onBack}
  //     />
  //   );
  // }

  return null;
};

SpaceOnboardingWizardContent.propTypes = {
  isSpaceWithProductizedRecipesBoards: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onBackFirstScreen: PropTypes.func,
  onChangeCurrentScreen: PropTypes.func,
  initialScreen: PropTypes.string,
  initialAnswers: PropTypes.arrayOf(PropTypes.string),
};

SpaceOnboardingWizardContent.defaultProps = {
  onBackFirstScreen: null,
  onChangeCurrentScreen: () => {},
  initialScreen: null,
  initialAnswers: null,
};

const SpaceOnboardingWizard = ({
  spaceID,
  isSpaceWithProductizedRecipesBoards,
  isLogoutEnabled,
  onFinish,
  onBack,
  onChangeCurrentScreen,
  initialScreen,
  initialAnswers,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSignOut = () => {
    dispatch(signOutAction());
    navigate(
      linkWithQueryParams(LOGIN_ROUTE, {
        intoSpace: spaceID,
      }),
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SpaceOnboardingWizardContent
        isSpaceWithProductizedRecipesBoards={
          isSpaceWithProductizedRecipesBoards
        }
        onFinish={onFinish}
        onBackFirstScreen={onBack}
        onChangeCurrentScreen={onChangeCurrentScreen}
        initialScreen={initialScreen}
        initialAnswers={initialAnswers}
      />
      <div className="footer">
        <div>
          {isLogoutEnabled && (
            <Button
              onClick={onSignOut}
              style={{ color: 'var(--app-primary-font-color)' }}>
              Log out
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

SpaceOnboardingWizard.propTypes = {
  spaceID: PropTypes.string.isRequired,
  isSpaceWithProductizedRecipesBoards: PropTypes.bool.isRequired,
  isLogoutEnabled: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onChangeCurrentScreen: PropTypes.func,
  initialScreen: PropTypes.string,
  initialAnswers: PropTypes.arrayOf(PropTypes.string),
};

SpaceOnboardingWizard.defaultProps = {
  onBack: null,
  onChangeCurrentScreen: () => {},
  initialScreen: null,
  initialAnswers: null,
};

export default SpaceOnboardingWizard;
