import { API, Auth, graphqlOperation } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';
import * as mutations from '../graphql/mutations';

export const getMixpanelGeolocationOperation = async (distinctId) => {
  const response = await API.graphql(
    graphqlOperation(mutations.getMixpanelUserGeolocation, {
      mixpanelDistinctId: distinctId,
    }),
  );
  return response.data.getMixpanelUserGeolocation;
};

export const initTracking = async () => {
  try {
    const currentUserInfo = await Auth.currentUserInfo();
    if (!currentUserInfo) {
      return;
    }
    const userId = currentUserInfo.username;
    const { email } = currentUserInfo.attributes;
    mixpanel.identify(userId);
    mixpanel.people.set({ $email: email });
    Sentry.setUser({
      id: userId,
      username: email,
      email,
    });
    /* Inspectlet */
    if (window.__insp) {
      window.__insp.push(['identify', email]);
      window.__insp.push(['tagSession', { email, userId }]);
    }
  } catch (e) {
    console.log(e);
  }
};

export const initTrackingAfterSignIn = (userId, email) => {
  mixpanel.identify(userId);
  mixpanel.people.set({ $email: email });
  incrementCounters(['numLogins']);
  Sentry.setUser({
    id: userId,
    username: email,
    email,
  });
  /* Inspectlet */
  if (window.__insp) {
    window.__insp.push(['identify', email]);
    window.__insp.push(['tagSession', { email, userId }]);
  }
};

export const trackingSetSpaceID = (spaceID) => {
  mixpanel.register({ space_id: spaceID });
};

export const reportParseRecipeUrlError = (url) => {
  Sentry.withScope((scope) => {
    scope.setFingerprint([url]);
    Sentry.captureException(new Error(`Parse recipe URL error: ${url}`), {
      tags: { smorgType: 'parse_recipe_url_error' },
    });
  });
};

export const resetTrackingOnSignOut = () => {
  mixpanel.reset();
  Sentry.configureScope((scope) => scope.clear());
};

const SUPPORTED_EVENTS = [
  { name: 'Sign up', mixpanelName: 'Sign up' },
  {
    name: 'Space landing page viewed',
    mixpanelName: 'Space landing page viewed',
  },
  { name: 'Clicked to join space', mixpanelName: 'Clicked to join space' },
  {
    name: 'Clicked to purchase subscription',
    mixpanelName: 'Clicked to purchase subscription',
  },
  { name: 'Subscription purchased', mixpanelName: 'Subscription purchased' },
  { name: 'View meal', mixpanelName: 'View meal' },
  { name: 'Shopping list viewed', mixpanelName: 'Shopping list viewed' },
  { name: 'Edit meal', mixpanelName: 'Edit meal' },
  { name: 'Nutrition added to meal', mixpanelName: 'Nutrition added to meal' },
  { name: 'Meals added to basket', mixpanelName: 'Meals added to basket' },
  { name: 'Recipe URL failed', mixpanelName: 'Recipe URL failed' },
  { name: 'Add to planner', mixpanelName: 'Add to planner' },
  { name: 'Add new meal', mixpanelName: 'Add new meal' },
  { name: 'Copy planner week', mixpanelName: 'Copy planner week' },
  { name: 'Planner clicked', mixpanelName: 'Planner clicked' },
  {
    name: 'List copied to clipboard',
    mixpanelName: 'List copied to clipboard',
  },
  { name: 'List emailed', mixpanelName: 'List copied to clipboard' },
  {
    name: 'Meals added to shopping list',
    mixpanelName: 'Meals added to shopping list',
  },
  { name: 'Products shopped', mixpanelName: 'Products shopped' },
  { name: 'Category tag selected', mixpanelName: 'Category tag selected' },
  { name: 'Category tag created', mixpanelName: 'Category tag created' },
  { name: 'Meal copied', mixpanelName: 'Meal copied' },
  { name: 'Menu copied', mixpanelName: 'Menu copied' },
  { name: 'Recipes board copied', mixpanelName: 'Recipes board copied' },
  { name: 'Recipes board imported', mixpanelName: 'Recipes board imported' },
  { name: 'Add new note', mixpanelName: 'Add new note' },
  { name: 'View note', mixpanelName: 'View note' },
  { name: 'Recipes board created', mixpanelName: 'Recipes board created' },
  { name: 'Switch to recipes board', mixpanelName: 'Switch to recipes board' },
  { name: 'Board made public', mixpanelName: 'Board made public' },
  { name: 'Board made private', mixpanelName: 'Board made private' },
  {
    name: 'Shared board link copied',
    mixpanelName: 'Shared board link copied',
  },
  { name: 'Search for meal', mixpanelName: 'Search for meal' },
  { name: 'Meal selected', mixpanelName: 'Meal selected' },
  { name: 'Recommender called', mixpanelName: 'Recommender called' },
  { name: 'Program started', mixpanelName: 'Program started' },
  { name: 'Content card clicked', mixpanelName: 'Content card clicked' },
];

const SUPPORTED_COUNTERS = [
  // numLogins is only incremented in Mixpanel
  { name: 'numLogins', mixpanelName: 'num_logins' },
  // All other counter names should correspond to fields of the activitySummary field of UserProfile
  { name: 'numMealsViewed', mixpanelName: 'num_meal_views' },
  { name: 'numMealsViewedMobile', mixpanelName: 'num_meal_views_mobile' },
  { name: 'numShoppingListsViewed', mixpanelName: 'num_lists_viewed' },
  {
    name: 'numShoppingListsViewedMobile',
    mixpanelName: 'num_shopping_list_views_mobile',
  },
  { name: 'numMealsEdited', mixpanelName: 'num_meals_edited' },
  { name: 'numNutritionClicks', mixpanelName: 'num_nutrition_clicks' },
  { name: 'numMealsPlanned', mixpanelName: 'num_meals_planned' },
  { name: 'numMealsAdded', mixpanelName: 'num_meals' },
  { name: 'numMealsImported', mixpanelName: 'num_meals_imported' },
  { name: 'numMealsAddedManually', mixpanelName: 'num_meals_manual' },
  { name: 'numPlansViewed', mixpanelName: 'num_plans_viewed' },
  {
    name: 'numShoppingListsCopiedToClipboard',
    mixpanelName: 'num_shopping_lists_copied_to_clipboard',
  },
  { name: 'numShoppingListsEmailed', mixpanelName: 'num_lists_emailed' },
  { name: 'numShoppingListsCreated', mixpanelName: 'num_shopping_lists' },
  { name: 'numShops', mixpanelName: 'num_shops' },
  { name: 'numNotesAdded', mixpanelName: 'num_notes' },
  { name: 'numNotesViewed', mixpanelName: 'num_notes_viewed' },
  { name: 'numNotesViewedMobile', mixpanelName: 'num_notes_viewed_mobile' },
  {
    name: 'numRecipesBoardsCreated',
    mixpanelName: 'num_recipes_boards_created',
  },
  { name: 'numRecipesBoardsShared', mixpanelName: 'num_recipes_boards_shared' },
];

const trackAsync = (...args) =>
  new Promise((resolve, reject) => {
    function callback(result) {
      if (result === 1) {
        resolve();
      } else {
        reject();
      }
    }

    args.push(callback);

    mixpanel.track(...args);
  });

/**
 *
 * @param trackingEvents array of strings or objects
 */
export const trackEvents = async (trackingEvents) => {
  const promises = trackingEvents.map((event) => {
    let eventName;
    let eventArgs;
    if (typeof event === 'string') {
      eventName = event;
    } else {
      // Object
      eventName = event.name;
      eventArgs = event.args;
    }
    if (SUPPORTED_EVENTS.find((e) => e.name === eventName)) {
      return trackAsync(eventName, eventArgs);
    }
    console.warn('Unsupported event name:', eventName, eventArgs);
    return Promise.resolve();
  });
  await Promise.all(promises);
};

export const incrementCounters = (counters) => {
  counters.forEach((counterName) => {
    const counterDesc = SUPPORTED_COUNTERS.find((c) => c.name === counterName);
    if (counterDesc) {
      const { mixpanelName } = counterDesc;
      if (mixpanelName) {
        mixpanel.people.increment(mixpanelName);
      }
    }
  });
};
