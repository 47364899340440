import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Icon from '@mdi/react';
import { mdiCalendarMonth } from '@mdi/js';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import { MovableCardWrapper } from '../common/board/styles/Base';
import contentEntryPlaceholder from '../../assets/images/content_entry_placeholder.png';
import EndUserRescheduleMealModal from '../meal/end_user_reschedule_meal_modal';
import { usePlannerViewWeekStartDate } from './planner_hooks';
import {
  contentEntryCopiedToPlannerAction,
  plannerEntryDeletedAction,
} from '../../action_creators/planner_action_creators';
import { trackContentClickAction } from '../../action_creators/content_entry_action_creators';

const PlannerEntryContentEntryCard = ({
  id,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  const dispatch = useDispatch();

  const hasTitle = !!title;

  const contentEntryID = style._objectID;
  const contentEntry = useSelector(
    (state) => state.contentEntries[contentEntryID],
  );

  const dayIndex = style._dayIndex;
  console.log({ dayIndex });
  const laneId = dayIndex.toString();

  const [deleteContentEntryAnchorEl, setDeleteContentEntryAnchorEl] =
    useState(null);

  const [rescheduleAnchorEl, setRescheduleAnchorEl] = useState(null);

  const coverImageUrl = useSelector(
    (state) => state.contentEntries[contentEntryID]?.coverImageUrl,
  );

  const plannerViewWeekStartDate = usePlannerViewWeekStartDate();

  const setGlobalSnackbar = (obj) =>
    dispatch({ type: 'SET_GLOBAL_SNACKBAR', ...obj });

  const onCopyToPlanner = (toPlannerViewWeekStartDate, dayIndexes) =>
    dispatch(
      contentEntryCopiedToPlannerAction(
        id,
        toPlannerViewWeekStartDate,
        dayIndexes,
      ),
    );

  const onMove = async (toPlannerViewWeekStartDate, toDayIndexes) => {
    await dispatch(
      contentEntryCopiedToPlannerAction(
        id,
        toPlannerViewWeekStartDate,
        toDayIndexes,
      ),
    );
    return dispatch(
      plannerEntryDeletedAction(plannerViewWeekStartDate, id, laneId),
    );
  };

  const handleClick = (ev) => {
    ev.stopPropagation();
    dispatch(trackContentClickAction(id, title, null, 'Planner'));
    onClick(id);
  };

  if (!contentEntry) {
    console.warn(
      `Rendering PlannerEntryContentEntry card: content entry not found`,
    );
    return null;
  }

  const imgClassNames = classNames('plannerCardImage', {
    cardWithNoTitle: !hasTitle,
  });

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={handleClick}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            className={imgClassNames}
            image={coverImageUrl || contentEntryPlaceholder}
            title={title}
          />
          {hasTitle && (
            <CardContent
              title={title}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderBottom: '1px solid #7B7B7B',
              }}>
              <Typography variant="body2" component="p" sx={{ flexGrow: 1 }}>
                {title}
              </Typography>
            </CardContent>
          )}
        </Box>
        <CardActions sx={{ justifyContent: 'space-around' }}>
          <Tooltip title="Reschedule" placement="top-start">
            <IconButton
              aria-label="reschedule"
              size="small"
              onClick={(ev) => {
                setRescheduleAnchorEl(ev.target);
                ev.stopPropagation();
              }}>
              <Icon
                path={mdiCalendarMonth}
                style={{ width: '24px', height: '24px' }}
              />
            </IconButton>
          </Tooltip>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(ev) => {
              setDeleteContentEntryAnchorEl(ev.target);
              ev.stopPropagation();
            }}>
            <DeleteIcon />
          </IconButton>
          <DeleteCardConfirmPopover
            visible={!!deleteContentEntryAnchorEl}
            anchorEl={deleteContentEntryAnchorEl}
            title="Delete content entry"
            message="The content entry will be deleted permanently. Are you sure?"
            height={155}
            onConfirmDelete={() => onDelete(id)}
            onDismiss={() => setDeleteContentEntryAnchorEl(null)}
          />
        </CardActions>
      </Card>
      {rescheduleAnchorEl && (
        <EndUserRescheduleMealModal
          anchorEl={rescheduleAnchorEl}
          onCopyToPlanner={(toPlannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(toPlannerViewWeekStartDate, dayIndexes);
            setRescheduleAnchorEl(null);
            const snackbarParams = {
              notificationText: 'Your meal has been copied',
            };
            if (plannerViewWeekStartDate !== toPlannerViewWeekStartDate) {
              snackbarParams.linkText = 'View planner';
              snackbarParams.linkTarget = `/planner/${toPlannerViewWeekStartDate}`;
            }
            setGlobalSnackbar(snackbarParams);
          }}
          onMove={(toPlannerViewWeekStartDate, dayIndexes) => {
            onMove(toPlannerViewWeekStartDate, dayIndexes);
            setRescheduleAnchorEl(null);
            const snackbarParams = {
              notificationText: 'Your meal has been rescheduled',
            };
            if (plannerViewWeekStartDate !== toPlannerViewWeekStartDate) {
              snackbarParams.linkText = 'View planner';
              snackbarParams.linkTarget = `/planner/${toPlannerViewWeekStartDate}`;
            }
            setGlobalSnackbar(snackbarParams);
          }}
          onDismiss={() => setRescheduleAnchorEl(null)}
        />
      )}
    </MovableCardWrapper>
  );
};

PlannerEntryContentEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PlannerEntryContentEntryCard;
