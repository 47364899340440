import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import PopupTitle from '../common/popup_title';
import { AppLinkScreen, AppLinkType } from '../../API';
import AppLinkObjectIDPickerChat from './app_link_object_id_picker_chat';
import AppLinkObjectIDPickerSharedContentBoards from './app_link_object_id_picker_shared_content_boards';
import AppLinkObjectIDPickerSharedProgrammes from './app_link_object_id_picker_shared_programmes';

const AppLinkTypeNothing = 'NOTHING';

const AppLinkEditModal = ({ initialAppLink, onChange, onDismiss }) => {
  const [appLink, setAppLink] = useState(initialAppLink || {});

  const appLinkType = appLink?.appLinkType || AppLinkTypeNothing;

  const handleChangeAppLinkType = (newAppLinkType) => {
    if (newAppLinkType === AppLinkType.APP_SCREEN) {
      setAppLink((oldAppLink) => ({
        ...(oldAppLink || {}),
        appLinkType: AppLinkType.APP_SCREEN,
        appLinkScreen: oldAppLink?.appLinkScreen || AppLinkScreen.RECIPES,
        objectID: null,
      }));
      return;
    }
    if (newAppLinkType === AppLinkType.URL) {
      setAppLink((oldAppLink) => ({
        ...(oldAppLink || {}),
        appLinkType: AppLinkType.URL,
        url: oldAppLink?.url || '',
        objectID: null,
      }));
      return;
    }
    setAppLink(null);
  };

  const handleChangeAppScreen = (newAppScreen) => {
    setAppLink((oldAppLink) => ({
      ...oldAppLink,
      appLinkScreen: newAppScreen,
      objectID: null,
    }));
  };

  const handleChangeObjectID = (newObjectID) => {
    setAppLink((oldAppLink) => ({
      ...oldAppLink,
      objectID: newObjectID,
    }));
  };

  const handleChangeUrl = (newUrl) => {
    setAppLink((oldAppLink) => ({
      ...oldAppLink,
      url: newUrl,
    }));
  };

  const handleSave = () => {
    onChange(appLink);
  };

  return (
    <Dialog open maxWidth="xs" fullWidth onClose={onDismiss}>
      <Grid container style={{ fontSize: '14px' }}>
        <Grid item xs={12}>
          <PopupTitle
            titleText="Link image to"
            closeButtonEnabled
            onClickClose={onDismiss}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '16px' }}>
          <RadioGroup
            value={appLinkType}
            onChange={(ev) => {
              handleChangeAppLinkType(ev.target.value);
            }}>
            <Grid container>
              <Grid item xs={4}>
                <FormControlLabel
                  value={AppLinkType.APP_SCREEN}
                  control={<Radio />}
                  label="App screen"
                />
              </Grid>
              <Grid item xs={4} style={{ display: 'flex' }}>
                <Select
                  variant="standard"
                  style={{ margin: 'auto 0' }}
                  value={appLink?.appLinkScreen || ''}
                  onChange={(ev) => {
                    const newValue = ev.target.value;
                    handleChangeAppScreen(newValue);
                  }}
                  disabled={appLinkType !== AppLinkType.APP_SCREEN}>
                  <MenuItem value={AppLinkScreen.RECIPES}>Recipes</MenuItem>
                  <MenuItem value={AppLinkScreen.PLANNER}>Planner</MenuItem>
                  <MenuItem value={AppLinkScreen.SHOPPING_LISTS}>
                    Shopping lists
                  </MenuItem>
                  <MenuItem value={AppLinkScreen.SHARED_PROGRAMMES}>
                    Programs
                  </MenuItem>
                  <MenuItem value={AppLinkScreen.CHAT}>Chat</MenuItem>
                  <MenuItem value={AppLinkScreen.SHARED_CONTENT_BOARDS}>
                    Content board
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4} style={{ display: 'flex' }}>
                {appLink?.appLinkScreen === AppLinkScreen.CHAT && (
                  <AppLinkObjectIDPickerChat
                    objectID={appLink?.objectID}
                    onChangeObjectID={handleChangeObjectID}
                  />
                )}
                {appLink?.appLinkScreen === AppLinkScreen.SHARED_PROGRAMMES && (
                  <AppLinkObjectIDPickerSharedProgrammes
                    objectID={appLink?.objectID}
                    onChangeObjectID={handleChangeObjectID}
                  />
                )}
                {appLink?.appLinkScreen === AppLinkScreen.SHARED_CONTENT_BOARDS && (
                  <AppLinkObjectIDPickerSharedContentBoards
                    objectID={appLink?.objectID}
                    onChangeObjectID={handleChangeObjectID}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value={AppLinkType.URL}
                  control={<Radio />}
                  label="External URL"
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="standard"
                  value={appLink?.url}
                  onChange={(ev) => {
                    handleChangeUrl(ev.target.value);
                  }}
                  disabled={appLinkType !== AppLinkType.URL}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value={AppLinkTypeNothing}
                  control={<Radio />}
                  label="No link"
                />
              </Grid>
              <Grid item xs={8}>
                &nbsp;
              </Grid>
            </Grid>
          </RadioGroup>
          <Grid item xs={12} style={{ padding: '5px' }}>
            <Button
              onClick={(ev) => {
                handleSave();
                ev.stopPropagation();
              }}
              variant="contained"
              size="medium"
              color="primary"
              disableElevation
              style={{ justifyContent: 'flex-start' }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AppLinkEditModal.propTypes = {
  initialAppLink: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

AppLinkEditModal.defaultProps = {
  initialAppLink: null,
};

export default AppLinkEditModal;
