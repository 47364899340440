import React from 'react';
import PropTypes from 'prop-types';
import ProgrammeEntryMealCard from './programme_entry_meal_card';
import ProgrammeEntryNoteCard from './programme_entry_note_card';
import { EntryType } from '../../API';
import ProgrammeEntryGRCRecipeCard from './programme_entry_grc_recipe_card';
import ProgrammeEntryContentEntryCard from './programme_entry_content_entry_card';
import ProgrammeEntrySharedContentEntryCard from './programme_entry_shared_content_entry_card';

const ProgrammeEntryCard = ({
  id,
  appBoardId,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  if (style?._programmeEntryType === EntryType.GRC_RECIPE) {
    return (
      <ProgrammeEntryGRCRecipeCard
        id={id}
        appBoardId={appBoardId}
        title={title}
        style={style}
        className={className}
        onClick={onClick}
        onDelete={onDelete}
        // isPreview={style?._isPreview}
        preferredMetricsWithRules={style?._preferredMetricsWithRules}
        targetNutrition={style?._targetNutrition}
        targetCalories={style?._targetCalories}
      />
    );
  }
  if (style?._programmeEntryType === EntryType.NOTE) {
    return (
      <ProgrammeEntryNoteCard
        id={id}
        appBoardId={appBoardId}
        title={title}
        style={style}
        className={className}
        onClick={onClick}
        onDelete={onDelete}
        // isPreview={style?._isPreview}
      />
    );
  }
  if (style?._programmeEntryType === EntryType.CONTENT_ENTRY) {
    return (
      <ProgrammeEntryContentEntryCard
        id={id}
        appBoardId={appBoardId}
        title={title}
        style={style}
        className={className}
        onClick={onClick}
        onDelete={onDelete}
        // isPreview={style?._isPreview}
      />
    );
  }
  if (style?._programmeEntryType === EntryType.SHARED_CONTENT_ENTRY_REFERENCE) {
    return (
      <ProgrammeEntrySharedContentEntryCard
        id={id}
        appBoardId={appBoardId}
        title={title}
        style={style}
        className={className}
        onClick={onClick}
        onDelete={onDelete}
        // isPreview={style?._isPreview}
      />
    );
  }
  return (
    <ProgrammeEntryMealCard
      id={id}
      appBoardId={appBoardId}
      title={title}
      style={style}
      className={className}
      onClick={onClick}
      onDelete={onDelete}
      // isPreview={style?._isPreview}
      preferredMetricsWithRules={style?._preferredMetricsWithRules}
      targetNutrition={style?._targetNutrition}
      targetCalories={style?._targetCalories}
    />
  );
};

ProgrammeEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ProgrammeEntryCard;
