import { API, graphqlOperation } from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';
import {
  ContentEntry,
  CreateProgrammeMutation,
  CreateSharedProgrammeInput,
  CreateSharedProgrammeMutation,
  CreateUserMyDayActionRecordMutation,
  CreateUserProgrammeEnrollmentMutation,
  DeleteProgrammeMutation,
  DeleteSharedProgrammeMutation,
  EntryType,
  GetProgrammeQuery,
  ListProgrammesQuery,
  ListUserProgrammeEnrollmentsQuery,
  Meal,
  Menu,
  ModelProgrammeConnection,
  MyDayActionType,
  OnboardingAnswer,
  OriginObjectType,
  Programme,
  ProgrammePlan,
  SharedProgramme,
  // UpdateProgrammeInput,
  UpdateProgrammeMutation,
  UpdateSharedProgrammeMutation,
  UpdateUserProgrammeEnrollmentInput,
  UpdateUserProgrammeEnrollmentMutation,
  UserMyDayActionRecord,
  UserMyDayActionRecordByUserProgrammeEnrollmentQuery,
  UserProgrammeEnrollment,
  UserProgrammeEnrollmentEventType,
} from '../API';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { getInputForUpdate } from './utils';
import { createSharedMeal, removeSharedMealOperation } from './meal_operations';
import {
  createSharedContentEntryOperation,
  removeSharedContentEntryOperation,
} from './content_entry_operations';
import {
  contentEntriesInProgrammes,
  mealsInProgrammes,
} from '../services/programmes';

// eslint-disable-next-line import/prefer-default-export
export const loadProgrammesOperation = async () => {
  const fetchPage = async (nextToken: string | null | undefined) => {
    const response = (await API.graphql(
      graphqlOperation(queries.listProgrammes, { nextToken }),
    )) as GraphQLResult<ListProgrammesQuery>;
    return response.data?.listProgrammes;
  };

  const programmes = [];
  let currentToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const page: ModelProgrammeConnection | null | undefined = await fetchPage(
      currentToken,
    );
    if (page) {
      const { items, nextToken }: ModelProgrammeConnection = page;
      currentToken = nextToken;
      programmes.push(...items);
    } else {
      console.warn('Page response was null or undefined');
      currentToken = null;
    }
  } while (currentToken !== null);

  return programmes;
};

export const getProgrammeOperation = async (programmeId: string) => {
  const response = (await API.graphql(
    graphqlOperation(queries.getProgramme, { id: programmeId }),
  )) as GraphQLResult<GetProgrammeQuery>;
  return response.data?.getProgramme as Programme;
};

export const createProgrammeOperation = async (programme: Programme) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.createProgramme, { input: programme }),
  )) as GraphQLResult<CreateProgrammeMutation>;
  return response.data?.createProgramme as Programme;
};

export const updateProgrammeOperation = async (programme: Programme) => {
  const input = getInputForUpdate(programme);
  const response = (await API.graphql(
    graphqlOperation(mutations.updateProgramme, { input }),
  )) as GraphQLResult<UpdateProgrammeMutation>;
  return response.data?.updateProgramme as Programme;
};

export const removeProgrammeOperation = async (programmeId: string) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.deleteProgramme, { input: { id: programmeId } }),
  )) as GraphQLResult<DeleteProgrammeMutation>;
  return response.data?.deleteProgramme;
};

const createSharedProgrammeOperation = async (
  input: CreateSharedProgrammeInput,
) => {
  const response = (await API.graphql({
    ...graphqlOperation(mutations.createSharedProgramme, { input }),
    // authMode: 'AWS_IAM',
  })) as GraphQLResult<CreateSharedProgrammeMutation>;
  return response.data?.createSharedProgramme;
};

const updateSharedProgrammeOperation = async (
  sharedProgramme: SharedProgramme,
) => {
  const input = getInputForUpdate(sharedProgramme);
  const response = (await API.graphql({
    ...graphqlOperation(mutations.updateSharedProgramme, { input }),
    // authMode: 'AWS_IAM',
  })) as GraphQLResult<UpdateSharedProgrammeMutation>;
  return response.data?.updateSharedProgramme;
};

export const removeSharedProgrammeOperation = async (
  sharedProgrammeID: string,
) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.deleteSharedProgramme, {
      input: { id: sharedProgrammeID },
    }),
  )) as GraphQLResult<DeleteSharedProgrammeMutation>;
  return response.data?.deleteSharedProgramme;
};

export const shareProgrammeOperation = async (
  sharedProgramme: CreateSharedProgrammeInput,
  linkedMeals: Record<string, Meal>,
  linkedContentEntries: Record<string, ContentEntry>,
  linkedDatabaseMeals: Record<string, Meal>,
) => {
  const createdSharedProgramme = await createSharedProgrammeOperation(
    sharedProgramme,
  );
  if (!createdSharedProgramme) {
    throw new Error('Could not create shared programme');
  }
  const mealIDs = [
    ...Object.keys(linkedMeals),
    ...Object.keys(linkedDatabaseMeals),
  ];
  const linkedMealPromises = Object.values(linkedMeals).map((meal) => {
    const sharedMeal = {
      schemaVersion: 1,
      sharedBoardID: createdSharedProgramme.recipesBoard?.id,
      recipes: meal.recipes,
      origin: {
        originObjectType: OriginObjectType.MEAL,
        originObjectID: meal.id,
      },
      derivedNutrition: meal.derivedNutrition,
    };
    return createSharedMeal(sharedMeal);
  });
  const linkedDatabaseMealPromises = Object.values(linkedDatabaseMeals).map(
    (meal) => {
      const sharedMeal = {
        schemaVersion: 1,
        sharedBoardID: createdSharedProgramme.databaseRecipesBoard?.id,
        recipes: meal.recipes,
        origin: {
          originObjectType: OriginObjectType.MEAL,
          originObjectID: meal.id,
        },
        derivedNutrition: meal.derivedNutrition,
      };
      return createSharedMeal(sharedMeal);
    },
  );
  const mealPromises = [...linkedMealPromises, ...linkedDatabaseMealPromises];
  const sharedMeals = await Promise.all(mealPromises);

  const contentEntryIDs = Object.keys(linkedContentEntries);
  const contentEntryPromises = Object.values(linkedContentEntries).map(
    (contentEntry) => {
      const sharedContentEntry = {
        parentID: createdSharedProgramme.id,
        title: contentEntry.title,
        body: contentEntry.body,
        coverImageUrl: contentEntry.coverImageUrl,
        isCoverImageShownInDetailView:
          contentEntry.isCoverImageShownInDetailView,
        tags: contentEntry.tags,
        origin: {
          originObjectType: OriginObjectType.CONTENT_ENTRY,
          originObjectID: contentEntry.id,
        },
      };
      return createSharedContentEntryOperation(sharedContentEntry);
    },
  );
  const sharedContentEntries = await Promise.all(contentEntryPromises);

  if (createdSharedProgramme.recipesBoard) {
    createdSharedProgramme.recipesBoard.menus =
      createdSharedProgramme.recipesBoard.menus.map(
        (menu) =>
          ({
            id: menu.id,
            title: menu.title,
            mealIDs: menu.mealIDs
              .filter((sourceMealID) => mealIDs.includes(sourceMealID))
              .map((sourceMealID) => {
                const index = mealIDs.indexOf(sourceMealID);
                return sharedMeals[index].id;
              }),
          } as Menu),
      );
  }
  if (createdSharedProgramme.databaseRecipesBoard) {
    createdSharedProgramme.databaseRecipesBoard.menus = (
      createdSharedProgramme.databaseRecipesBoard.menus || []
    ).map(
      (menu) =>
        ({
          id: menu.id,
          title: menu.title,
          mealIDs: menu.mealIDs
            .filter((sourceMealID) => mealIDs.includes(sourceMealID))
            .map((sourceMealID) => {
              const index = mealIDs.indexOf(sourceMealID);
              return sharedMeals[index].id;
            }),
        } as Menu),
    );
  }
  createdSharedProgramme.plans = createdSharedProgramme.plans.map(
    (plan) =>
      ({
        ...plan,
        days: plan.days.map((day) => ({
          ...day,
          entries: day.entries
            .map((entry) => {
              if (entry.programmeEntryType === EntryType.GRC_RECIPE) {
                // GRC recipe entries pass through unchanged
                return entry;
              }

              if (entry.programmeEntryType === EntryType.MEAL) {
                const sourceMealID = entry.objectID;
                const index = mealIDs.indexOf(sourceMealID);
                if (index === -1) {
                  console.warn(
                    `Could not find shared meal for meal ID ${sourceMealID}`,
                  );
                  return entry;
                }
                return {
                  ...entry,
                  objectID: sharedMeals[index].id,
                };
              }

              if (entry.programmeEntryType === EntryType.CONTENT_ENTRY) {
                const sourceContentEntryID = entry.objectID;
                const index = contentEntryIDs.indexOf(sourceContentEntryID);
                if (index === -1) {
                  return null;
                }
                const sharedContentEntry = sharedContentEntries[index];
                if (!sharedContentEntry) {
                  console.warn(
                    `Could not find shared content entry for content entry ID ${sourceContentEntryID}`,
                  );
                  return null;
                }
                return {
                  ...entry,
                  objectID: sharedContentEntry.id,
                };
              }

              if (
                entry.programmeEntryType ===
                EntryType.SHARED_CONTENT_ENTRY_REFERENCE
              ) {
                return entry;
              }

              // Other entry types do not pass through
              return null;
            })
            .filter((entry) => !!entry),
        })),
      } as ProgrammePlan),
  );
  await updateSharedProgrammeOperation(createdSharedProgramme);
  return createdSharedProgramme;
};

export const unshareProgrammeOperation = async (
  sharedProgramme: SharedProgramme,
  oldSharedMealIDs: Array<string>,
) => {
  const sharedContentEntryIDs = contentEntriesInProgrammes([sharedProgramme]);
  console.log(
    `Unpublishing programme: Removing ${oldSharedMealIDs.length} shared meals and ${sharedContentEntryIDs.length} shared content entries`,
  );
  const removePromises = [
    ...oldSharedMealIDs.map((sharedMealID) =>
      removeSharedMealOperation(sharedMealID),
    ),
    ...sharedContentEntryIDs.map((sharedContentEntryID) =>
      removeSharedContentEntryOperation(sharedContentEntryID),
    ),
  ];
  await Promise.all(removePromises);
  return removeSharedProgrammeOperation(sharedProgramme.id);
};

const calendarDayFromJSDate = (jsDate: Date) =>
  jsDate.toISOString().substring(0, 10);

export const createUserProgrammeEnrollmentOperation = async (
  spaceMembershipID: string,
  sharedProgrammeID: string,
  onboardingAnswers: Array<OnboardingAnswer>,
  programmeStartDate: string | null | undefined,
  customTargetCalories: number | null | undefined,
) => {
  const now = new Date();
  const enrollDate = calendarDayFromJSDate(now);
  const actualStartDate = programmeStartDate || enrollDate;
  const input = {
    spaceMembershipID,
    sharedProgrammeID,
    enrolledAt: now.toISOString(),
    onboardingAnswers,
    customTargetCalories,
    eventRecords: [
      {
        calendarDay: enrollDate,
        eventType: UserProgrammeEnrollmentEventType.USER_ENROLLED,
        updatedOn: now,
      },
      {
        calendarDay: actualStartDate,
        eventType: UserProgrammeEnrollmentEventType.USER_STARTED,
        updatedOn: now,
      },
    ],
  };
  const response = (await API.graphql(
    graphqlOperation(mutations.createUserProgrammeEnrollment, { input }),
  )) as GraphQLResult<CreateUserProgrammeEnrollmentMutation>;
  return response.data?.createUserProgrammeEnrollment;
};

export const getUserProgrammeEnrollmentsOperation = async (
  spaceMembershipID: string,
) => {
  const foundEnrollments: Array<UserProgrammeEnrollment> = [];
  const fetchPage = async (nextToken: string | null | undefined) => {
    const allEnrollmentsResponse = (await API.graphql(
      graphqlOperation(queries.listUserProgrammeEnrollments, {
        nextToken,
      }),
    )) as GraphQLResult<ListUserProgrammeEnrollmentsQuery>;
    const allEnrollments =
      allEnrollmentsResponse.data?.listUserProgrammeEnrollments?.items || [];
    allEnrollments.forEach((enrollment) => {
      // TODO should use an index
      if (enrollment && enrollment.spaceMembershipID === spaceMembershipID) {
        foundEnrollments.push(enrollment);
      }
    });
    return allEnrollmentsResponse.data?.listUserProgrammeEnrollments?.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return foundEnrollments;
};

// export const getActiveUserProgrammeEnrollmentOperation = async (
//   spaceMembershipID: string, sharedProgrammeID: string,
// ) => {
//   let foundEnrollment = null;
//   const fetchPage = async (nextToken: string | null | undefined) => {
//     const allEnrollmentsResponse = (await API.graphql(
//       graphqlOperation(queries.userProgrammeEnrollmentBySharedProgrammeID, {
//         sharedProgrammeID,
//         nextToken,
//       }),
//     )) as GraphQLResult<UserProgrammeEnrollmentBySharedProgrammeIDQuery>;
//     const allEnrollments =
//       allEnrollmentsResponse.data?.userProgrammeEnrollmentBySharedProgrammeID
//         ?.items || [];
//     allEnrollments.forEach((enrollment) => {
//       if (enrollment && !enrollment.endedAt) {
//         foundEnrollment = enrollment;
//       }
//     });
//     return allEnrollmentsResponse.data
//       ?.userProgrammeEnrollmentBySharedProgrammeID?.nextToken;
//   };

//   let nextToken = null;
//   do {
//     // eslint-disable-next-line no-await-in-loop
//     nextToken = await fetchPage(nextToken);
//   } while (nextToken !== null);

//   return foundEnrollment;
// };

export const updateUserProgrammeEnrollmentOperation = async (
  userProgrammeEnrollment: UpdateUserProgrammeEnrollmentInput,
) => {
  const input = getInputForUpdate(
    userProgrammeEnrollment,
  ) as UpdateUserProgrammeEnrollmentInput;
  const response = (await API.graphql(
    graphqlOperation(mutations.updateUserProgrammeEnrollment, {
      input,
    }),
  )) as GraphQLResult<UpdateUserProgrammeEnrollmentMutation>;
  return response.data?.updateUserProgrammeEnrollment;
};

export const getUserMyDayActionRecordsOperation = async (
  userProgrammeEnrollmentID: string,
) => {
  const userMyDayActionRecords: Array<UserMyDayActionRecord> = [];
  const fetchPage = async (nextToken: string | null | undefined) => {
    const allRecordsResponse = (await API.graphql(
      graphqlOperation(queries.userMyDayActionRecordByUserProgrammeEnrollment, {
        userProgrammeEnrollmentID,
        nextToken,
      }),
    )) as GraphQLResult<UserMyDayActionRecordByUserProgrammeEnrollmentQuery>;
    // eslint-disable-next-line no-restricted-syntax
    for (const record of allRecordsResponse.data
      ?.userMyDayActionRecordByUserProgrammeEnrollment?.items || []) {
      if (record) {
        userMyDayActionRecords.push(record);
      }
    }
    return allRecordsResponse.data
      ?.userMyDayActionRecordByUserProgrammeEnrollment?.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return userMyDayActionRecords;
};

export const createUserMyDayActionRecordOperation = async (
  userProgrammeEnrollmentID: string,
  programmeEntryType: EntryType,
  objectID: string,
  parentID: string | null | undefined,
  calendarDay: string,
  actionType: MyDayActionType,
) => {
  const input = {
    userProgrammeEnrollmentID,
    programmeEntryType,
    objectID,
    parentID,
    calendarDay,
    actionType,
  };
  const response = (await API.graphql(
    graphqlOperation(mutations.createUserMyDayActionRecord, { input }),
  )) as GraphQLResult<CreateUserMyDayActionRecordMutation>;
  return response.data?.createUserMyDayActionRecord;
};
