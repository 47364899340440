import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { sanitize } from '../../services/sanitize';
import { currentEndUserSpaceIDSelector } from '../../reducers/user_reducer';
import { suggestedCustomerIngredientNamesLabel } from '../../services/users';

const IngredientInput = ({
  inputId,
  ingredient,
  suggestions,
  suggestionsLoading,
  disabled,
  onChange,
  onDismiss,
}) => {
  const [editableIngFullText, setEditableIngFullText] = useState(
    ingredient.fullText,
  );

  const changeAndDismiss = (data, reason) => {
    onChangeRaw(data, reason);
    onDismiss(reason);
  };

  const resetAndDismiss = (reason) => {
    resetInput();
    onDismiss(reason);
  };

  const resetInput = () => {
    setEditableIngFullText(ingredient.fullText);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      changeAndDismiss(editableIngFullText, 'enter-key');
    }
    if (event.keyCode === 27) {
      resetAndDismiss('escape-key');
    }
    return null;
  };

  const onPaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('Text');
    changeAndDismiss(pastedText, 'enter-key');
    return null;
  };

  const onChangeRaw = (value, reason) => {
    const sanitized = sanitize(value);
    return onChange(sanitized, reason);
  };

  useEffect(() => {
    window.setTimeout(() => document.getElementById(inputId).focus(), 200);
  }, [inputId]);

  const onAutocompleteChange = (ev, data, reason) => {
    console.log(`Change: ${data} ${reason}`);
    if (reason === 'reset') {
      return;
    }
    if (reason === 'selectOption') {
      const selectedSuggestionText = data.suggestionText;
      setEditableIngFullText(selectedSuggestionText);
      changeAndDismiss(selectedSuggestionText, 'selectOption');
    } else {
      setEditableIngFullText(data);
    }
  };

  const currentSpaceID = useSelector(currentEndUserSpaceIDSelector);

  const suggestionGroups = [
    {
      key: 'undo',
      label: 'Undo',
    },
    {
      key: 'history',
      label: 'History',
    },
    {
      key: 'suggested_measures',
      label: 'Alternative Measures',
    },
    {
      key: 'suggested_ingredient_names',
      label: 'Alternative Ingredients',
    },
  ];

  const customerIngredientsLabel =
    suggestedCustomerIngredientNamesLabel(currentSpaceID);

  if (customerIngredientsLabel) {
    suggestionGroups.splice(2, 0, {
      key: 'suggested_customer_ingredient_names',
      label: customerIngredientsLabel,
    });
  }

  const autocompleteOptions = [];
  if (ingredient.structuredIngredient) {
    const { linkedIngredient, quantity, unitOfMeasure } =
      ingredient.structuredIngredient;
    if (linkedIngredient) {
      autocompleteOptions.push({
        groupLabel: `Recognised ingredient data:`,
      });
      autocompleteOptions.push({
        label: `Ingredient: ${linkedIngredient.linkedIngredientName}`,
        indent: true,
      });
      autocompleteOptions.push({
        label: `Measure: ${quantity} ${unitOfMeasure}`,
        indent: true,
      });
    } else {
      autocompleteOptions.push({
        groupLabel: 'Ingredient was not recognised',
      });
    }
  }
  suggestionGroups.forEach((group) => {
    const suggestionsInGroup = suggestions.filter(
      (s) => s.suggestionType === group.key,
    );
    if (suggestionsInGroup.length > 0) {
      autocompleteOptions.push({ groupLabel: group.label });
      suggestionsInGroup.forEach((s) => {
        let [highlightIndexStart, highlightIndexEnd] = [0, 0];
        if (s.highlightTokens.length >= 1) {
          [highlightIndexStart, highlightIndexEnd] = [
            s.highlightTokens[0].fromChar,
            s.highlightTokens[0].toChar,
          ];
        }
        autocompleteOptions.push({
          suggestionText: s.suggestionText,
          highlightIndexStart,
          highlightIndexEnd,
          indent: true,
        });
      });
    }
  });

  const loadingPseudoOption = [{ groupLabel: 'Loading...' }];

  const renderOption = (liProps, option) => {
    if (option.groupLabel) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...liProps}>{option.groupLabel}</li>
      );
    }
    if (option.label) {
      return (
        <li
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...liProps}
          style={{
            opacity: 1,
            marginLeft: option.indent ? '20px' : 'initial',
          }}>
          {option.label}
        </li>
      );
    }
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <li {...liProps} style={option.indent && { marginLeft: '20px' }}>
        {option.highlightIndexStart > 0 && (
          <span>
            {option.suggestionText.substring(0, option.highlightIndexStart)}
            &nbsp;
          </span>
        )}
        <span className="suggestionHighlight">
          {option.suggestionText.substring(
            option.highlightIndexStart,
            option.highlightIndexEnd,
          )}
        </span>
        <span>
          &nbsp;{option.suggestionText.substring(option.highlightIndexEnd)}
        </span>
      </li>
    );
  };

  const getOptionDisabled = (option) => {
    return !!option.groupLabel || !!option.label;
  };

  return (
    <Autocomplete
      id={inputId}
      freeSolo
      size="small"
      value={editableIngFullText}
      options={
        suggestionsLoading
          ? [...autocompleteOptions, ...loadingPseudoOption]
          : autocompleteOptions
      }
      disableClearable
      open
      autoFocus
      fullWidth
      disabled={disabled}
      onBlur={() => changeAndDismiss(editableIngFullText, 'blur')}
      onKeyDown={onKeyDown}
      onInputChange={onAutocompleteChange}
      onChange={onAutocompleteChange}
      onPaste={onPaste}
      renderOption={renderOption}
      getOptionDisabled={getOptionDisabled}
      // eslint-disable-next-line react/no-unstable-nested-components
      PaperComponent={({ children }) => (
        <Paper style={{ background: 'white' }} sx={{ boxShadow: 3 }}>
          {children}
        </Paper>
      )}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField size="small" variant="standard" {...params} />
      )}
    />
  );
};

IngredientInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  ingredient: PropTypes.shape({
    fullText: PropTypes.string.isRequired,
    tokFullText: PropTypes.string,
    tokens: PropTypes.arrayOf(
      PropTypes.shape({
        fromChar: PropTypes.number,
        toChar: PropTypes.number,
        type: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    structuredIngredient: PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      unitOfMeasure: PropTypes.string.isRequired,
      linkedIngredient: PropTypes.shape({
        linkedIngredientName: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      suggestionText: PropTypes.string.isRequired,
      suggestionType: PropTypes.string.isRequired,
      highlightTokens: PropTypes.shape({
        fromChar: PropTypes.number.isRequired,
        toChar: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  suggestionsLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

IngredientInput.defaultProps = {
  disabled: false,
};

export default IngredientInput;
