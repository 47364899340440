import React, { useCallback, useEffect, useState } from 'react';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, createTheme, useTheme } from '@mui/material';
import MealAdvancedSearchCriteria from '../meal/meal_advanced_search_criteria';
import {
  GRC_RESULTS_GROUP_LABEL,
  useMealSearch,
} from '../common/meal_search_hook';
import {
  currentProgrammeEnrollmentSelector,
  sharedProgrammeLocalesSelector,
} from '../../reducers/programmes_reducer';
import PopupTitle from '../common/popup_title';
import { EntryType } from '../../API';
import {
  currentEndUserSpaceSelector,
  userLocaleSelector,
} from '../../reducers/user_reducer';
import { allIngredientNames } from '../../services/ingredients';
import { ensureSharedProgrammeLatestVersionLoadedAction } from '../../action_creators/programmes_action_creators';
import { endUserSharedPogrammeRecipesBoardIDsForSearchSelector } from '../../reducers/recipes_reducer';
import EndUserFindAlternativesSearchTopResultsView from './end_user_find_alternatives_search_top_results_view';
import EndUserFindAlternativesSearchGroupResultsView from './end_user_find_alternatives_search_group_results_view';
import {
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
} from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';

const SEARCH_RESULTS_GROUP_LABEL = 'Search results';

const buildSearchCriteriaTheme = (appTheme) => {
  return createTheme(appTheme, {
    palette: {
      primary: {
        main: '#427a46',
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: 'var(--app-primary-font-color)',
            '&:before': {
              borderBottomColor: 'var(--app-primary-font-color) !important',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: 'var(--app-primary-font-color)',
            borderColor: 'var(--app-primary-font-color)',
            '&:hover': {
              color: 'var(--app-primary-font-color)',
            },
          },
        },
      },
    },
  });
};

const EndUserFindAlternativesSearchMealsView = () => {
  const navigate = useNavigate();

  const { calendarDay } = useParams();

  const [searchParams] = useSearchParams();

  const mealIDToReplace = searchParams.get('mealId');
  const mealType = searchParams.get('mealType');
  const scrollCookie = searchParams.get('scrollCookie');

  const dispatch = useDispatch();

  const appTheme = useTheme();

  const searchCriteriaTheme = buildSearchCriteriaTheme(appTheme);

  const recentlyUsedSearchCriteria = useSelector(
    (state) => state.userProfile?.recentlyUsedSearchCriteria || {},
  );

  const [searchCriteria, setSearchCriteria] = useState(
    recentlyUsedSearchCriteria,
  );

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment?.sharedProgrammeID &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );

  const locales = useSelector((state) =>
    currentProgrammeEnrollment?.sharedProgrammeID
      ? sharedProgrammeLocalesSelector(
          state,
          currentProgrammeEnrollment.sharedProgrammeID,
        )
      : [userLocaleSelector(state)],
  );

  const myRecipesBoardIDs = useSelector((state) =>
    state.recipesBoards.map((board) => board.id).filter((id) => !!id),
  );

  const programmeRecipesBoardsIDs = useSelector(
    endUserSharedPogrammeRecipesBoardIDsForSearchSelector,
  );

  console.log(JSON.stringify({ programmeRecipesBoardsIDs }));

  const groupsConfig = [
    {
      groupLabel: SEARCH_RESULTS_GROUP_LABEL,
      parentIDs: [...myRecipesBoardIDs, ...programmeRecipesBoardsIDs],
    },
  ];

  const [results, estimatedCount, isLoading, onLoadMore, ingredientTreeNodes] =
    useMealSearch(searchCriteria, groupsConfig, 'recipes', locales, false);

  const loadedResultsCount = results
    ? results.reduce((acc, group) => acc + group.data.length, 0)
    : 0;

  const moreAvailable = results.some(
    (groupResults) => groupResults.moreAvailable,
  );

  const resultsWithActions = results.map((groupResults) => ({
    ...groupResults,
    entryType:
      groupResults.groupLabel === GRC_RESULTS_GROUP_LABEL
        ? EntryType.GRC_RECIPE
        : EntryType.MEAL,
  }));

  const onDismiss = () => navigate(-1);

  const sharedProgrammeID = sharedProgramme?.id;

  useEffect(() => {
    if (sharedProgrammeID) {
      dispatch(
        ensureSharedProgrammeLatestVersionLoadedAction(sharedProgrammeID),
      );
    }
  }, [dispatch, sharedProgrammeID]);

  const allTags = useSelector((state) =>
    (currentEndUserSpaceSelector(state)?.publishedSearchTags || []).map(
      (t) => t.tag,
    ),
  );

  const onGoToGroup = useCallback(
    (groupLabel) => {
      navigate(
        linkWithQueryParams(
          formatRoute(
            END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_ROUTE,
            {
              calendarDay,
              groupLabel,
            },
          ),
          { mealType, mealId: mealIDToReplace, scrollCookie },
        ),
      );
    },
    [navigate],
  );

  const location = useLocation();

  // const [searchParams] = useSearchParams();

  const showTopResults =
    matchPath(
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_ROUTE,
      location.pathname,
    ) ||
    matchPath(
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
      location.pathname,
    );

  const showGroupResults =
    matchPath(
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_ROUTE,
      location.pathname,
    ) ||
    matchPath(
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
      location.pathname,
    );

  console.log({ showTopResults, showGroupResults });

  const { groupLabel: groupResultsGroupLabel } = useParams();

  return (
    <div className="recipes-search">
      {!showTopResults && !showGroupResults && (
        <div className="recipes-search-form">
          <div style={{ color: 'var(--app-primary-font-color)' }}>
            <PopupTitle
              titleText="Search for a meal"
              backButtonEnabled
              onClickBack={onDismiss}
            />
          </div>
          <div style={{ height: 'calc(var(--app-height) - 106px)' }}>
            <ThemeProvider theme={searchCriteriaTheme}>
              <MealAdvancedSearchCriteria
                searchCriteria={searchCriteria}
                onChangeSearchCriteria={setSearchCriteria}
                totalResultsLoading={isLoading}
                totalResults={estimatedCount}
                loadedResultsCount={loadedResultsCount}
                moreAvailable={moreAvailable}
                onShowResults={() => {
                  navigate(
                    linkWithQueryParams(
                      formatRoute(
                        END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_ROUTE,
                        { calendarDay },
                      ),
                      { mealType, mealId: mealIDToReplace, scrollCookie },
                    ),
                  );
                }}
                onDismiss={onDismiss}
                allIngredients={allIngredientNames(ingredientTreeNodes)}
                allTags={allTags}
              />
            </ThemeProvider>
          </div>
        </div>
      )}
      {showTopResults && (
        <div className="recipes-search-results">
          <EndUserFindAlternativesSearchTopResultsView
            results={resultsWithActions}
            onGoToGroup={onGoToGroup}
            onLoadMore={onLoadMore}
            onBack={() => {
              navigate(
                -1,
                /* formatRoute(END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_ROUTE, {
                  calendarDay,
                }), */
              );
            }}
            onDismiss={() => navigate(-1)}
            calendarDay={calendarDay}
            mealType={mealType}
            mealIDToReplace={mealIDToReplace}
            searchCriteria={searchCriteria}
            onChangeSearchCriteria={setSearchCriteria}
          />
        </div>
      )}
      {showGroupResults && (
        <div className="recipes-search-results">
          <EndUserFindAlternativesSearchGroupResultsView
            groupResults={resultsWithActions.find(
              (r) => r.groupLabel === groupResultsGroupLabel,
            )}
            onLoadMore={onLoadMore}
            onBack={() => {
              navigate(
                -1,
                /* formatRoute(
                  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_ROUTE,
                  { calendarDay },
                ), */
              );
            }}
            onDismiss={() => navigate(-1)}
            calendarDay={calendarDay}
            mealType={mealType}
            mealIDToReplace={mealIDToReplace}
            searchCriteria={searchCriteria}
            onChangeSearchCriteria={setSearchCriteria}
          />
        </div>
      )}
    </div>
  );
};

export default EndUserFindAlternativesSearchMealsView;
