import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { Card, CardContent, CardMedia, Grid } from '@mui/material';
import { MY_DAY_CONTENT_ENTRY_ROUTE } from '../../services/routes';
import { trackContentClickAction } from '../../action_creators/content_entry_action_creators';
import { handleClickContentCard } from '../../services/content_entries';

const MyDayCompactContentCard = ({ contentEntryID }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const theme = useTheme();

  const contentEntryExists = useSelector(
    (state) =>
      !!state.sharedContentEntries[contentEntryID] ||
      !!state.contentEntries[contentEntryID],
  );

  const title = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.title,
  );

  const hasTitle = !!title;

  const coverImageUrl = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.coverImageUrl,
  );

  const appLink = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.appLink,
  );

  if (!contentEntryExists) {
    return null;
  }

  const handleClick = () =>
    handleClickContentCard(
      appLink,
      (hyperlink) => {
        dispatch(
          trackContentClickAction(contentEntryID, title, hyperlink, 'My Day'),
        );
      },
      navigate,
      () => {
        navigate(
          formatRoute(MY_DAY_CONTENT_ENTRY_ROUTE, {
            sharedContentEntryID: contentEntryID,
          }),
        );
      },
    );

  const imgClassNames = classNames('myDayCompactContentEntryCardImage', {
    cardWithNoTitle: !hasTitle,
  });

  return (
    <Card
      className="my-day-compact-content-card"
      onClick={handleClick}
      sx={{ display: 'flex', flexDirection: 'column' }}
      style={{ backgroundColor: theme.palette.primary.cardBackground }}>
      <Grid item xs={12}>
        {coverImageUrl ? (
          <CardMedia
            component="img"
            className={imgClassNames}
            image={coverImageUrl}
            alt=""
          />
        ) : (
          <div className={imgClassNames} />
        )}
      </Grid>
      {hasTitle && (
        <Grid item xs={12}>
          <CardContent
            sx={{
              flex: '1 0 auto',
              margin: '8px',
              padding: '0 !important',
              fontSize: '14px',
              whiteSpace: 'break-spaces',
            }}
            style={{
              color: 'white',
              lineClamp: 3,
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </CardContent>
        </Grid>
      )}
    </Card>
  );
};

MyDayCompactContentCard.propTypes = {
  contentEntryID: PropTypes.string.isRequired,
};

export default MyDayCompactContentCard;
