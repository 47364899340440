import React, { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, Popover, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PopupTitle from '../common/popup_title';

const SharedContentLinkToProgrammeModal = ({ anchorEl, onLink, onDismiss }) => {
  const availableProgrammes = useSelector((state) => state.programmes);

  const [selectedProgrammeID, setSelectedProgrammeID] = useState(
    (availableProgrammes || [])[0]?.id,
  );

  const [selectedPlanID, setSelectedPlanID] = useState(null);

  const [selectedDayID, setSelectedDayID] = useState(null);

  const eligiblePlans = (
    availableProgrammes.find((b) => b.id === selectedProgrammeID)?.plans || []
  ).map((m) => ({ id: m.id, title: m.title }));

  const bestInitialPlanID = eligiblePlans[0]?.id;

  useEffect(() => {
    setSelectedPlanID(bestInitialPlanID);
  }, [bestInitialPlanID]);

  const eligibleDays = (
    (
      availableProgrammes.find((b) => b.id === selectedProgrammeID)?.plans || []
    ).find((pl) => pl.id === selectedPlanID)?.days || []
  ).map((d) => ({ id: d.id, title: d.title }));

  const bestInitialDayID = eligibleDays[0]?.id;

  useEffect(() => {
    setSelectedDayID(bestInitialDayID);
  }, [bestInitialDayID]);

  const clearState = () => {
    setSelectedProgrammeID(null);
  };

  const onClose = () => {
    clearState();
    onDismiss();
  };

  const onProgrammeSelectChange = (event) => {
    setSelectedProgrammeID(event.target.value);
  };

  const onPlanSelectChange = (event) => {
    console.log(`onPlanSelectChange ${event.target.value}`);
    setSelectedPlanID(event.target.value);
  };

  const onDaySelectChange = (event) => {
    console.log(`onDaySelectChange ${event.target.value}`);
    setSelectedDayID(event.target.value);
  };

  console.log({ selectedProgrammeID, selectedPlanID, selectedDayID });

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '240px', height: '310px', padding: '10px' },
      }}
      onClose={onClose}
      onClick={(ev) => ev.stopPropagation()}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <Grid container style={{ fontSize: '14px' }}>
        <Grid item xs={12}>
          <PopupTitle
            titleText="Copy to programme"
            closeButtonEnabled
            onClickClose={onClose}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
          Select programme:
        </Grid>
        <Grid item xs={12} style={{ padding: '0 5px 5px 5px' }}>
          <Select
            variant="standard"
            value={selectedProgrammeID}
            onChange={onProgrammeSelectChange}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem disabled value="">
              <em>Select board</em>
            </MenuItem>
            {availableProgrammes.map((pr) => (
              <MenuItem key={pr.id} value={pr.id}>
                {pr.title}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
          Select week:
        </Grid>
        <Grid item xs={12} style={{ padding: '0 5px 5px 5px' }}>
          <Select
            variant="standard"
            value={selectedPlanID}
            onChange={onPlanSelectChange}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem disabled value="">
              <em>Select plan</em>
            </MenuItem>
            {eligiblePlans.map((pl) => (
              <MenuItem key={pl.id} value={pl.id}>
                {pl.title}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
          Select day:
        </Grid>
        <Grid item xs={12} style={{ padding: '0 5px 5px 5px' }}>
          <Select
            variant="standard"
            value={selectedDayID}
            onChange={onDaySelectChange}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem disabled value="">
              <em>Select day</em>
            </MenuItem>
            {eligibleDays.map((d) => (
              <MenuItem key={d.id} value={d.id}>
                {d.title}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onLink(selectedProgrammeID, selectedPlanID, selectedDayID);
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            disabled={!selectedProgrammeID || !selectedPlanID || !selectedDayID}
            style={{ justifyContent: 'flex-start' }}>
            Copy
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

SharedContentLinkToProgrammeModal.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onLink: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default SharedContentLinkToProgrammeModal;
