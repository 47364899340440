import { formatRoute } from 'react-router-named-routes';
import { AppLinkScreen, AppLinkType } from '../API';
import {
  END_USER_CHAT_GROUP_ROUTE,
  END_USER_CHAT_ROUTE,
  END_USER_CONTENT_BOARD_ROUTE,
  END_USER_RECIPES_ROUTE,
  END_USER_SHARED_PROGRAMME_ROUTE,
  END_USER_SHARED_PROGRAMMES_ROUTE,
} from './routes';

export const contentEntryWithSectionUpdated = (
  contentEntry,
  section,
  newText,
) => ({
  ...contentEntry,
  [section]: newText,
});

export const sharedContentEntryWithSectionUpdated = (
  sharedContentEntry,
  section,
  newText,
) => ({
  ...sharedContentEntry,
  [section]: newText,
});

export const contentEntriesWithContentEntriesUpdated = (
  contentEntries,
  updatedContentEntries,
) => {
  const newContentEntries = { ...contentEntries };
  updatedContentEntries.forEach((updatedContentEntry) => {
    newContentEntries[updatedContentEntry.id] = updatedContentEntry;
  });
  return newContentEntries;
};

export const sharedContentEntriesWithSharedContentEntriesUpdated = (
  sharedContentEntries,
  updatedSharedContentEntries,
) => {
  const newContentEntries = { ...sharedContentEntries };
  updatedSharedContentEntries.forEach((updatedSharedContentEntry) => {
    newContentEntries[updatedSharedContentEntry.id] = updatedSharedContentEntry;
  });
  return newContentEntries;
};

export const contentEntryPreviewHtml = (body, coverImageUrl) => {
  if (coverImageUrl) {
    const paddedPreviewHtml = `
    <img src='${coverImageUrl}' style='width: 100%; min-height: 50px; object-fit: contain;' />
    <div style='padding: 15px 15px 0;' />
    ${body}
    </div>
    `;
    return paddedPreviewHtml;
  }
  const paddedPreviewHtml = `
  <div style='height: 50px'></div>
  <div style='padding: 15px 15px 0;'>
  ${body}
  </div>
  `;
  return paddedPreviewHtml;
};

export const handleClickContentCard = (
  appLink,
  onTrackBeforeClick,
  navigate,
  onClick,
) => {
  if (appLink) {
    if (appLink.appLinkType === AppLinkType.APP_SCREEN) {
      let appScreenRoute = null;
      switch (appLink.appLinkScreen) {
        case AppLinkScreen.RECIPES:
          appScreenRoute = END_USER_RECIPES_ROUTE;
          break;
        case AppLinkScreen.PLANNER:
          appScreenRoute = '/planner';
          break;
        case AppLinkScreen.SHOPPING_LISTS:
          appScreenRoute = '/products';
          break;
        case AppLinkScreen.SHARED_PROGRAMMES:
          if (appLink.objectID) {
            appScreenRoute = formatRoute(END_USER_SHARED_PROGRAMME_ROUTE, {
              sharedProgrammeId: appLink.objectID,
            });
          } else {
            appScreenRoute = END_USER_SHARED_PROGRAMMES_ROUTE;
          }
          break;
        case AppLinkScreen.CHAT:
          if (appLink.objectID) {
            appScreenRoute = formatRoute(END_USER_CHAT_GROUP_ROUTE, {
              groupID: appLink.objectID,
            });
          } else {
            appScreenRoute = END_USER_CHAT_ROUTE;
          }
          break;
        case AppLinkScreen.SHARED_CONTENT_BOARDS:
          if (appLink.objectID) {
            appScreenRoute = formatRoute(END_USER_CONTENT_BOARD_ROUTE, {
              boardID: appLink.objectID,
            });
          } else {
            console.warn(
              `Missing content board ID for app link screen of type SHARED_CONTENT_BOARDS`,
            );
          }
          break;
        default:
          console.warn(`Unknown app link screen ${appLink.appLinkScreen}`);
      }
      if (appScreenRoute) {
        onTrackBeforeClick(appScreenRoute);
        navigate(appScreenRoute);
      }
    } else if (appLink.appLinkType === AppLinkType.URL) {
      if (appLink.url) {
        onTrackBeforeClick(appLink.url);
        window.open(appLink.url, '_blank');
      } else {
        console.warn(`Bad URL ${appLink.url}`);
      }
    } else {
      console.warn(`Unknown app link type ${appLink.appLinkType}`);
    }
    return;
  }
  onTrackBeforeClick(null);
  onClick();
};
