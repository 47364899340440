import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { IconButton } from '@mui/material';
import { mdiPlusCircle } from '@mdi/js';
import RecipeCollectionAddMealMenu from './recipe_collection_add_meal_menu';
import {
  END_USER_RECIPE_COLLECTION_ADD_MEALS_ROUTE,
  END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE,
} from '../../services/routes';
import { newMealAddedAction } from '../../action_creators/recipes_action_creators';
import { newBlankMeal, recipeCollectionsCardDOMID } from '../../services/meals';

const RecipeCollectionAddNewMealButton = ({ boardID, menuID }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const onDismissMenu = () => {
    setMenuAnchorEl(null);
  };

  const onClickSearchMeals = () => {
    navigate(
      formatRoute(END_USER_RECIPE_COLLECTION_ADD_MEALS_ROUTE, {
        boardID,
        menuID,
      }),
    );
  };

  const onClickCreateMeal = () => {
    const newMeal = newBlankMeal();
    dispatch(
      newMealAddedAction(boardID, menuID, newMeal, 0, (insertedMeal) => {
        // Go to the opened meal
        navigate(
          formatRoute(END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE, {
            boardID,
            mealID: insertedMeal.id,
          }),
        );
        window.setTimeout(() => {
          const el = document.getElementById(
            recipeCollectionsCardDOMID(insertedMeal.id),
          );
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
        }, 200);
      }),
    );
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          margin: '10px 23px',
          cursor: 'pointer',
          fontWeight: 800,
          display: 'flex',
        }}
        onClick={(ev) => setMenuAnchorEl(ev.target)}>
        <IconButton aria-label="add" size="small" style={{ padding: 0 }}>
          <Icon
            path={mdiPlusCircle}
            style={{
              width: '24px',
              height: '24px',
              color: 'var(--app-primary-font-color)',
              marginRight: '8px',
            }}
          />
        </IconButton>
        <div style={{ margin: 'auto 0', fontSize: '14px' }}>Add meal</div>
      </div>
      {menuAnchorEl && (
        <RecipeCollectionAddMealMenu
          anchorEl={menuAnchorEl}
          onDismiss={onDismissMenu}
          onCreateMeal={onClickCreateMeal}
          onSearchMeals={onClickSearchMeals}
        />
      )}
    </>
  );
};

RecipeCollectionAddNewMealButton.propTypes = {
  boardID: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,
};

export default RecipeCollectionAddNewMealButton;
