import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Board from '../common/board';
import AddCardLink from './add_card_link';
import NewMenuForm from './new_menu_form';
import NewMenuButton from './new_menu_button';
import MenuHeader from './menu_header';
import { deepEqual } from '../../services/smorg_redux_store';
import { scrollNewCardIntoView } from '../../services/smorg_board';
import {
  mealDeletedAction,
  mealMovedAction,
  mealOrSharedMealCopiedToRecipesBoardAction,
  menuAddedAction,
  menuDeletedAction,
  menuMovedAction,
  menuRenamedAction,
  newMealAddedAction,
} from '../../action_creators/recipes_action_creators';
import {
  isBoardPublishedToSpaceSelector,
  isRecipesBoardBlankSelector,
  mealsCardsForRecipesBoardSelector,
  recipesBoardByIdSelector,
  sharedMealsCardsForRecipesBoardSelector,
  // mealsForRecipesBoardSelector,
} from '../../reducers/recipes_reducer';
import { useIsMobile } from '../common/layout_hooks';
import usePrevious from '../../services/use_previous';
import NewRecipesEntryForm from './new_recipes_entry_form';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import MobileBoardLaneNavigator from '../common/mobile_board_lane_navigator';
import MealBasketWidget from '../meal/meal_basket_widget';
import { ContainerType } from '../../API';
import { isProductizedSharedBoardID } from '../../services/productized_shared_boards';
import RecipesBoardCard from './recipes_board_card';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const labels = {
  'Add another lane': '+ Add another menu',
  'Delete lane': 'Delete menu',
  'Lane actions': 'Menu actions',
  button: {
    'Add lane': 'Add menu',
    'Add card': 'Add meal',
    Cancel: 'Cancel',
  },
  placeholder: {
    title: 'title',
    description: 'description',
    label: 'label',
  },
};

const customTranslation = (key) => labels[key];

const components = {
  AddCardLink,
  NewCardForm: NewRecipesEntryForm,
  Card: RecipesBoardCard,
  NewLaneForm: NewMenuForm,
  NewLaneSection: NewMenuButton,
  LaneHeader: MenuHeader,
};

/**
 * Recipes board view for desktops, using Trello-like lanes.
 * Users who see this:
 *  - Smorg Studio users aka health pros
 *  - End users of the community website
 *  - End users on desktop webapp
 */
const RecipesBoardTrelloView = ({
  recipesBoardId,
  boardCss,
  heightRuleCss,
  freeHeight,
  onClickMeal,
  blankBoardHelpContent,
}) => {
  const dispatch = useDispatch();

  const userIsEndUser = !useSelector(userIsCreatorSelector);

  const theme = useTheme();

  const recipesBoard = useSelector((state) =>
    recipesBoardByIdSelector(state, recipesBoardId),
  );

  const meals = useSelector(
    (state) => mealsCardsForRecipesBoardSelector(state, recipesBoardId),
    deepEqual,
  );

  const sharedMeals = useSelector(
    (state) => sharedMealsCardsForRecipesBoardSelector(state, recipesBoardId),
    deepEqual,
  );

  const prevRecipesBoardId = usePrevious(recipesBoardId);

  useEffect(() => {
    if (recipesBoardId !== prevRecipesBoardId) {
      // After switching to a new board, make sure the first lane is
      // visible at the top left
      window.setTimeout(() => {
        const selector = `.smooth-dnd-draggable-wrapper:first-child`;
        const firstLaneEl = document.querySelector(selector);
        if (firstLaneEl) {
          console.log('Scrolling firstLaneEl into view');
          firstLaneEl.scrollIntoView();
        }
      }, 100);
    }
  }, [prevRecipesBoardId, recipesBoardId]);

  const prevMeals = usePrevious(meals);

  useEffect(() => {
    // If a new meal was just added, scroll the lane
    // to make the new meal visible.
    scrollNewCardIntoView(meals, prevMeals);
  }, [meals, prevMeals]);

  const isSharedProgrammeBoard = [
    ContainerType.PROGRAMME,
    ContainerType.SHARED_PROGRAMME,
  ].includes(recipesBoard?.embeddedInContainerType);

  const isBoardPublishedToSpace = useSelector((state) =>
    isBoardPublishedToSpaceSelector(state, recipesBoardId),
  );

  const isProductizedSharedBoard =
    isBoardPublishedToSpace || isProductizedSharedBoardID(recipesBoardId);

  const isReadOnly =
    userIsEndUser && (isSharedProgrammeBoard || isProductizedSharedBoard);

  const reactTrelloCardDataForMeal = (mealID, menuID) => {
    const meal = meals[mealID] || sharedMeals[mealID];
    if (!meal) {
      // Meal not yet loaded
      return null;
    }
    return {
      id: meal.id,
      title: meal.recipes[0].title,
      description: meal.recipes[0].shortDescription || '',
      style: { _readOnly: isReadOnly, _menuID: menuID },
    };
  };

  const reactTrelloCardDataForAIJob = (aiJob) => {
    return {
      id: aiJob.recipeJobID,
      title: aiJob.aiPrompt,
      style: { _recipeJobID: aiJob.recipeJobID },
    };
  };

  const recipesAIGenerationJobs = useSelector(
    (state) => state.recipesAIGenerationJobs,
  );

  const reactTrelloCardsForBoardMenu = (menu) => {
    const mealCardData = (menu?.mealIDs || []).map((mealID) =>
      reactTrelloCardDataForMeal(mealID, menu.id),
    );
    const aiJobCardData = recipesAIGenerationJobs
      .filter(
        (aiJob) =>
          aiJob.recipesBoardID === recipesBoardId && aiJob.menuID === menu.id,
      )
      .map(reactTrelloCardDataForAIJob);
    return [...mealCardData, ...aiJobCardData];
  };

  const reactTrelloDataForBoardMenu = (menu) => ({
    id: menu.id,
    title: menu.title,
    titleStyle: isReadOnly && {
      _isBoardReadOnly: isReadOnly,
    },
    cards: reactTrelloCardsForBoardMenu(menu).filter((cardData) => !!cardData),
  });

  const reactTrelloData = {
    lanes: (recipesBoard?.menus || []).map(reactTrelloDataForBoardMenu),
  };

  const stringifiedReactTrelloData = JSON.stringify(reactTrelloData);
  const memoizedReactTrelloData = useMemo(
    () => reactTrelloData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedReactTrelloData],
  );

  const afterCardAdd = useCallback(
    (reactTrelloCard, laneId) => {
      console.log({ reactTrelloCard });
      const newMeal = reactTrelloCard.style._meal;
      const searchResultMealID = reactTrelloCard.style._searchResult?.mealID;
      const shouldOpenMealDetail =
        !!reactTrelloCard.style?._should_open_meal_detail;
      if (newMeal) {
        dispatch(
          newMealAddedAction(
            recipesBoardId,
            laneId,
            newMeal,
            null,
            (insertedMeal) => {
              if (shouldOpenMealDetail) {
                onClickMeal(insertedMeal.id);
              }
            },
          ),
        );
      } else if (searchResultMealID) {
        dispatch(
          mealOrSharedMealCopiedToRecipesBoardAction(
            searchResultMealID,
            recipesBoardId,
            laneId,
            (copiedMeal) => {
              window.setTimeout(() => {
                const el = document.getElementById(
                  recipeCollectionsCardDOMID(copiedMeal.id),
                );
                if (el) {
                  el.scrollIntoView({ behavior: 'smooth' });
                }
              }, 200);
            },
          ),
        );
      }
    },
    [dispatch, onClickMeal, recipesBoardId],
  );

  const onCardClick = useCallback(
    (cardId, metadata, laneId) => {
      console.log({ cardId, metadata, laneId });
      const meal = meals[cardId] || sharedMeals[cardId];
      console.log({ meal });
      if (!meal) {
        return;
      }
      onClickMeal(cardId);
    },
    [meals, onClickMeal, sharedMeals],
  );

  const afterCardDrag = useCallback(
    (
      cardId,
      sourceLaneId,
      targetLaneId,
      position,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      cardDetails,
    ) => {
      dispatch(
        mealMovedAction(
          recipesBoardId,
          cardId,
          sourceLaneId,
          targetLaneId,
          position,
        ),
      );
      // Cancel the drop, because the board will re-render with the new structure.
      return false;
    },
    [dispatch, recipesBoardId],
  );

  const afterLaneUpdate = useCallback(
    (laneId, data) => {
      console.log({ laneId, data });
      // Only the title can be edited
      const updatedTitle = data.title;
      if (updatedTitle && updatedTitle.length > 0) {
        dispatch(menuRenamedAction(recipesBoardId, laneId, updatedTitle));
      }
    },
    [dispatch, recipesBoardId],
  );

  const afterLaneAdd = useCallback(
    (params) => {
      const { id, title } = params;
      if (title && title.length > 0) {
        dispatch(menuAddedAction(recipesBoardId, id, title));
      }
    },
    [dispatch, recipesBoardId],
  );

  const afterLaneDelete = useCallback(
    (laneId) => {
      dispatch(menuDeletedAction(recipesBoardId, laneId));
    },
    [dispatch, recipesBoardId],
  );

  const afterLaneDrag = useCallback(
    (removedIndex, addedIndex) => {
      dispatch(menuMovedAction(recipesBoardId, removedIndex, addedIndex));
    },
    [dispatch, recipesBoardId],
  );

  const afterCardDelete = useCallback(
    (cardId, laneId) => {
      dispatch(mealDeletedAction(recipesBoardId, cardId, laneId));
    },
    [dispatch, recipesBoardId],
  );

  const isBlank = useSelector((state) =>
    isRecipesBoardBlankSelector(state, recipesBoardId),
  );

  const isMobile = useIsMobile();

  const boardScrollableRef = useRef();

  document.documentElement.style.setProperty(
    '--recipes-free-height',
    `${freeHeight}px`,
  );

  return (
    <div
      className={classNames('smorg-board-container', {
        userIsEndUser /* , mealDetailVisible */,
      })}
      id="smorg-board">
      <MealBasketWidget />
      <Board
        appBoardId={recipesBoardId}
        editable={!isReadOnly}
        draggable={!isReadOnly}
        cardDraggable={!isReadOnly}
        editLaneTitle={!isReadOnly}
        canAddLanes={!isReadOnly}
        data={memoizedReactTrelloData}
        components={components}
        onCardAdd={afterCardAdd}
        onCardClick={onCardClick}
        onLaneAdd={afterLaneAdd}
        onLaneDelete={afterLaneDelete}
        onLaneUpdate={afterLaneUpdate}
        onCardDelete={afterCardDelete}
        handleDragEnd={afterCardDrag}
        handleLaneDragEnd={afterLaneDrag}
        boardScrollableRef={boardScrollableRef}
        backdropContent={
          <>
            {isMobile && (
              <MobileBoardLaneNavigator
                boardScrollableRef={boardScrollableRef}
                laneIDs={reactTrelloData.lanes.map((l) => l.id)}
                positionStyle={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                }}
                renderItem={(itemIndexZeroBased, isSelected, onClick) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    style={{
                      backgroundColor: isSelected
                        ? theme.palette.primary.light
                        : theme.palette.grey[300],
                      borderRadius: '50%',
                      width: 10,
                      height: 10,
                      margin: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={onClick}>
                    &nbsp;
                  </div>
                )}
              />
            )}
            {isBlank && blankBoardHelpContent}
          </>
        }
        style={boardCss}
        backdropStyle={heightRuleCss}
        t={customTranslation}
      />
    </div>
  );
};

RecipesBoardTrelloView.propTypes = {
  recipesBoardId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  boardCss: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  heightRuleCss: PropTypes.object.isRequired,
  freeHeight: PropTypes.number.isRequired,
  onClickMeal: PropTypes.func.isRequired,
  blankBoardHelpContent: PropTypes.element,
};

RecipesBoardTrelloView.defaultProps = {
  blankBoardHelpContent: null,
};

export default RecipesBoardTrelloView;
