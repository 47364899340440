import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import Icon from '@mdi/react';
import { mdiCheckOutline } from '@mdi/js';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
// import contentEntryPlaceholder from '../../assets/images/content_entry_placeholder.png';
import { MY_DAY_CONTENT_ENTRY_ROUTE } from '../../services/routes';
import { myDayMarkContentEntryWatchedAction } from '../../action_creators/my_day_action_creators';
import { trackContentClickAction } from '../../action_creators/content_entry_action_creators';

const MyDayContentCard = ({
  contentEntryID,
  parentID,
  calendarDay,
  showMarkAsCompleted,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const theme = useTheme();

  const contentEntryExists = useSelector(
    (state) =>
      !!state.sharedContentEntries[contentEntryID] ||
      !!state.contentEntries[contentEntryID],
  );

  const title = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.title,
  );

  const coverImageUrl = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.coverImageUrl,
  );

  const handleClick = (ev) => {
    ev.stopPropagation();
    dispatch(trackContentClickAction(contentEntryID, title, null, 'My Day'));
    navigate(
      formatRoute(MY_DAY_CONTENT_ENTRY_ROUTE, {
        sharedContentEntryID: contentEntryID,
      }),
    );
  };

  if (!contentEntryExists) {
    return null;
  }

  return (
    <Card
      className="my-day-card"
      onClick={handleClick}
      sx={{ display: 'flex', flexDirection: 'column' }}
      style={{ backgroundColor: theme.palette.primary.cardBackground }}>
      <Grid item xs={12}>
        <CardMedia
          component="img"
          className="myDayContentEntryCardImage"
          image={coverImageUrl}
          alt=""
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={10}>
            <CardContent
              sx={{
                flex: '1 0 auto',
                margin: '8px',
                padding: '0 !important',
                fontSize: '14px',
                whiteSpace: 'break-spaces',
              }}
              style={{
                color: 'white',
                lineClamp: 3,
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}>
              {title}
            </CardContent>
          </Grid>
          {showMarkAsCompleted && (
            <Grid
              item
              xs={2}
              style={{ backgroundColor: 'white', display: 'flex' }}>
              <Tooltip title="Mark as watched" placement="top-start">
                <IconButton
                  aria-label="unskip"
                  size="small"
                  style={{ margin: 'auto' }}
                  onClick={(ev) => {
                    dispatch(
                      myDayMarkContentEntryWatchedAction(
                        contentEntryID,
                        parentID,
                        calendarDay,
                      ),
                    );
                    ev.stopPropagation();
                  }}>
                  <Icon
                    path={mdiCheckOutline}
                    style={{ width: '24px', height: '24px' }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

MyDayContentCard.propTypes = {
  contentEntryID: PropTypes.string.isRequired,
  parentID: PropTypes.string.isRequired,
  calendarDay: PropTypes.string.isRequired,
  showMarkAsCompleted: PropTypes.bool,
};

MyDayContentCard.defaultProps = {
  showMarkAsCompleted: false,
};

export default MyDayContentCard;
