import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import PopupTitle from '../common/popup_title';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import RecipeBoardsPickerNewBoardPage from '../recipes/recipe_boards_picker_new_board_page';
import { SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE } from '../../services/routes';
import { createSharedContentBoardAction } from '../../action_creators/spaces_action_creators';

const SharedContentBoardActionsPopupMenu = ({
  sharedContentBoardID,
  deleteBoardDisabled,
  onDeleteBoard,
}) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const [deletePopupAnchorEl, setDeletePopupAnchorEl] = useState(null);

  const sharedContentBoards = useSelector((state) => state.sharedContentBoards);

  const [showNewBoardPage, setShowNewBoardPage] = useState(false);
  const [creationInProgress, setCreationInProgress] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setShowNewBoardPage(false);
    setAnchorEl(null);
  };

  const handleCreateNewBoard = (title) => {
    setShowNewBoardPage(false);
    handleClose();
    setCreationInProgress(true);
    dispatch(
      createSharedContentBoardAction(title, (result) => {
        setCreationInProgress(false);
        const newSharedContentBoardID = result?.id;
        if (newSharedContentBoardID) {
          navigate(
            formatRoute(SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE, {
              sharedContentBoardID: newSharedContentBoardID,
            }),
          );
        }
      }),
    );
  };

  const handleDeleteBoard = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onDeleteBoard();
  };

  const onSelectBoard = (boardID) => {
    handleClose();
    // dispatch(trackAction(['Switch to content board']));
    navigate(
      formatRoute(SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE, {
        sharedContentBoardID: boardID,
      }),
    );
  };

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          style: { minWidth: '250px' },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {showNewBoardPage ? (
          <RecipeBoardsPickerNewBoardPage
            handleClose={handleClose}
            handleClickBack={() => setShowNewBoardPage(false)}
            onCreateNewBoard={handleCreateNewBoard}
            creationInProgress={creationInProgress}
            defaultTitle="Lessons"
          />
        ) : (
          <>
            <PopupTitle
              titleText="Choose option"
              closeButtonEnabled
              onClickClose={handleClose}
            />
            <MenuItem onClick={() => setShowNewBoardPage(true)}>
              Create new content board
            </MenuItem>
            <MenuItem
              onClick={(ev) => setDeletePopupAnchorEl(ev.target)}
              disabled={deleteBoardDisabled}>
              Delete board
            </MenuItem>
            <MenuItem
              sx={{ fontWeight: 'bold', opacity: '1 !important' }}
              disabled>
              Switch to
            </MenuItem>
            {sharedContentBoards.map((sharedContentBoard) => (
              <MenuItem
                disabled={sharedContentBoard.id === sharedContentBoardID}
                key={sharedContentBoard.id}
                onClick={() => {
                  onSelectBoard(sharedContentBoard.id);
                }}>
                {sharedContentBoard.title}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open && 'true'}
        aria-haspopup="true"
        size="small"
        className="actionsPopupMenu"
        onClick={handleClick}>
        <Icon path={mdiDotsHorizontal} />
      </IconButton>
      <DeleteCardConfirmPopover
        anchorEl={deletePopupAnchorEl}
        visible={!!deletePopupAnchorEl}
        onDismiss={() => setDeletePopupAnchorEl(null)}
        message="The board and all content items will be deleted permanently. Are you sure?"
        onConfirmDelete={handleDeleteBoard}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Delete all content items"
      />
    </>
  );
};

SharedContentBoardActionsPopupMenu.propTypes = {
  sharedContentBoardID: PropTypes.string.isRequired,
  deleteBoardDisabled: PropTypes.bool.isRequired,
  onDeleteBoard: PropTypes.func.isRequired,
};

export default SharedContentBoardActionsPopupMenu;
