import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { sharedProgrammesSelector } from '../../reducers/user_reducer';

const AppLinkObjectIDPickerSharedProgrammes = ({
  objectID,
  onChangeObjectID,
}) => {
  const sharedProgrammes = useSelector(sharedProgrammesSelector);

  return (
    <Select
      variant="standard"
      style={{ margin: 'auto 0', maxWidth: '140px' }}
      value={objectID || ''}
      displayEmpty
      onChange={(ev) => {
        const newValue = ev.target.value;
        onChangeObjectID(newValue);
      }}>
      <MenuItem value="">
        <em>Programs screen</em>
      </MenuItem>
      {sharedProgrammes.map((sharedProgramme) => (
        <MenuItem key={sharedProgramme.id} value={sharedProgramme.id}>
          {sharedProgramme.title}
        </MenuItem>
      ))}
    </Select>
  );
};

AppLinkObjectIDPickerSharedProgrammes.propTypes = {
  objectID: PropTypes.string,
  onChangeObjectID: PropTypes.func.isRequired,
};

AppLinkObjectIDPickerSharedProgrammes.defaultProps = {
  objectID: '',
};

export default AppLinkObjectIDPickerSharedProgrammes;
