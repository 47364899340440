export const MINIMUM_PASSWORD_LENGTH = 8;

export const suggestedCustomerIngredientNamesLabel = (currentSpaceID) => {
  if (
    currentSpaceID === '48646413-d3fd-4808-bab5-c5a108cab03f' ||
    currentSpaceID === 'ae5a029f-d08e-447e-a818-cb342b97afb7'
  ) {
    // Wildbiome
    return 'Alternative Wild Ingredients';
  }
  return null;
};
