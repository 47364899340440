import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { MY_DAY_CONTENT_ENTRY_ROUTE } from '../../services/routes';
import NarrowEndUserContentEntryCard from './narrow_end_user_content_entry_card';
import { handleClickContentCard } from '../../services/content_entries';
import { trackContentClickAction } from '../../action_creators/content_entry_action_creators';

const EndUserContentEntryItem = ({ contentEntryID }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const contentEntryExists = useSelector(
    (state) =>
      !!state.sharedContentEntries[contentEntryID] ||
      !!state.contentEntries[contentEntryID],
  );

  const title = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.title,
  );

  const coverImageUrl = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.coverImageUrl,
  );

  const appLink = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.appLink,
  );

  if (!contentEntryExists) {
    return null;
  }

  const onClick = () =>
    handleClickContentCard(
      appLink,
      (hyperlink) => {
        dispatch(
          trackContentClickAction(contentEntryID, title, hyperlink, 'My Day'),
        );
      },
      navigate,
      () => {
        navigate(
          formatRoute(MY_DAY_CONTENT_ENTRY_ROUTE, {
            sharedContentEntryID: contentEntryID,
          }),
        );
      },
    );

  return (
    <NarrowEndUserContentEntryCard
      title={title}
      coverImageUrl={coverImageUrl}
      onClick={onClick}
    />
  );
};

EndUserContentEntryItem.propTypes = {
  contentEntryID: PropTypes.string.isRequired,
};

export default EndUserContentEntryItem;
