import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';

const ProgrammeProgressContentEntryCard = ({ contentEntry }) => {
  if (!contentEntry) {
    return null;
  }

  return (
    <Card
      className="timeline-card"
      sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <CardMedia
          component="img"
          className="plannerCardImage"
          image={contentEntry.coverImageUrl || imgPlaceholder}
          title={contentEntry.title}
        />
        <CardContent
          title={contentEntry.title}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid #7B7B7B',
          }}>
          <Typography
            variant="body2"
            component="p"
            sx={{ flexGrow: 1 }}
            style={{
              lineClamp: 2,
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {contentEntry.title}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

ProgrammeProgressContentEntryCard.propTypes = {
  contentEntry: PropTypes.string.isRequired,
};

export default ProgrammeProgressContentEntryCard;
