import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CardActions, IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Icon from '@mdi/react';
import { mdiCalendarMonth, mdiFileMove, mdiArrowAll } from '@mdi/js';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import AddToPlannerModal from './add_to_planner_modal';
import { copyToBoardAvailableSelector } from '../../reducers/recipes_reducer';
import CopyToBoardModal from './copy_to_board_modal';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import { END_USER_RECIPES_BOARD_ROUTE } from '../../services/routes';
import EndUserOrganiseRecipeModal from './end_user_organise_recipe_modal';

const MealCardActions = ({
  recipesBoardId,
  isReadOnly,
  onDelete,
  onQuickDuplicate,
  onCopyToPlanner,
  copyToBoardEnabled,
  onCopyToRecipesBoard,
  organizeEnabled,
  onMoveToRecipesBoard,
  isInMealBasket,
  onAddToMealBasket,
  onRemoveFromMealBasket,
  setGlobalSnackbar,
  copyToProgrammeEnabled,
  destinationProgrammeIDs,
  onCopyToProgramme,
  quickDuplicateEnabled,
}) => {
  const copyToBoardAvailable = useSelector((state) =>
    copyToBoardAvailableSelector(state, recipesBoardId),
  );

  const [deleteMealAnchorEl, setDeleteMealAnchorEl] = useState(null);

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  const [copyToBoardAnchorEl, setCopyToBoardAnchorEl] = useState(null);

  const userIsCreator = useSelector(userIsCreatorSelector);

  const onClickQuickDuplicate = useCallback(
    (ev) => {
      onQuickDuplicate();
      ev.stopPropagation();
    },
    [onQuickDuplicate],
  );

  const onClickDelete = useCallback((ev) => {
    setDeleteMealAnchorEl(ev.target);
    ev.stopPropagation();
  }, []);

  const onClickAddToBasket = useCallback(
    (ev) => {
      onAddToMealBasket();
      ev.stopPropagation();
    },
    [onAddToMealBasket],
  );

  const onClickRemoveFromBasket = useCallback(
    (ev) => {
      onRemoveFromMealBasket();
      ev.stopPropagation();
    },
    [onRemoveFromMealBasket],
  );

  const onClickAddToPlanner = useCallback((ev) => {
    setAddToPlannerAnchorEl(ev.target);
    ev.stopPropagation();
  }, []);

  const onClickCopyToBoardOrOrganise = useCallback((ev) => {
    setCopyToBoardAnchorEl(ev.target);
    ev.stopPropagation();
  }, []);

  const copyToPlannerEnabled = !userIsCreator;
  const copyToProgrammesEnabled = userIsCreator && copyToProgrammeEnabled;
  const copytoPlanButtonEnabled =
    copyToPlannerEnabled || copyToProgrammesEnabled;

  return (
    <CardActions sx={{ justifyContent: 'space-around' }}>
      {copytoPlanButtonEnabled && (
        <Tooltip title="Copy to plan" placement="top-start">
          <IconButton
            aria-label="copy to plan"
            size="small"
            onClick={onClickAddToPlanner}>
            <Icon
              path={mdiCalendarMonth}
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Tooltip>
      )}
      {quickDuplicateEnabled && (
        <Tooltip title="Duplicate this meal" placement="top-start">
          <IconButton
            aria-label="quick duplicate"
            size="small"
            onClick={onClickQuickDuplicate}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      )}
      {copyToBoardEnabled && copyToBoardAvailable && (
        <Tooltip title="Copy to board" placement="top-start">
          <IconButton
            aria-label="Copy to board"
            size="small"
            onClick={onClickCopyToBoardOrOrganise}>
            <Icon
              path={mdiFileMove}
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Tooltip>
      )}
      {organizeEnabled && (
        <Tooltip title="Organise" placement="top-start">
          <IconButton
            aria-label="Organise"
            size="small"
            onClick={onClickCopyToBoardOrOrganise}>
            <Icon
              path={mdiArrowAll}
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Tooltip>
      )}
      {!isReadOnly && !userIsCreator && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isInMealBasket ? (
            <Tooltip
              title="Don't add this meal to shopping list"
              placement="top-start">
              <IconButton
                aria-label="remove from shopping list"
                size="small"
                onClick={onClickRemoveFromBasket}>
                <ShoppingCartIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Add this meal to a shopping list"
              placement="top-start">
              <IconButton
                aria-label="add to shopping list"
                size="small"
                onClick={onClickAddToBasket}>
                <AddShoppingCartIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {!isReadOnly && (
        <Tooltip title="Delete meal" placement="top-start">
          <IconButton aria-label="delete" size="small" onClick={onClickDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      <DeleteCardConfirmPopover
        visible={!!deleteMealAnchorEl}
        anchorEl={deleteMealAnchorEl}
        title="Delete meal"
        message="The meal will be deleted permanently. Are you sure?"
        height={140}
        onConfirmDelete={onDelete}
        onDismiss={() => setDeleteMealAnchorEl(null)}
      />
      {addToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={addToPlannerAnchorEl}
          copyToPlannerEnabled={copyToPlannerEnabled}
          copyToProgrammesEnabled={copyToProgrammeEnabled}
          destinationProgrammeIDs={destinationProgrammeIDs}
          onCopyToPlanner={(plannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            setGlobalSnackbar({
              notificationText: 'Your meal has been copied to your planner',
              linkText: 'View planner',
              linkTarget: `/planner/${plannerViewWeekStartDate}`,
            });
          }}
          onCopyToProgramme={(programmeId, planId, dayIndexes) => {
            onCopyToProgramme(programmeId, planId, dayIndexes);
            setAddToPlannerAnchorEl(null);
            setGlobalSnackbar({
              notificationText: 'Your meal has been copied to your program',
              linkText: 'View program',
              linkTarget: `/programmes/${programmeId}/plans/${planId}`,
            });
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
        />
      )}
      {copyToBoardAnchorEl && copyToBoardEnabled && (
        <CopyToBoardModal
          recipesBoardId={recipesBoardId}
          menuSelectionEnabled
          anchorEl={copyToBoardAnchorEl}
          onCopy={(toRecipesBoardId, toMenuId) => {
            onCopyToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            setGlobalSnackbar({
              notificationText: 'Your meal has been copied to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          onDismiss={() => setCopyToBoardAnchorEl(null)}
        />
      )}
      {copyToBoardAnchorEl && organizeEnabled && (
        <EndUserOrganiseRecipeModal
          recipesBoardId={recipesBoardId}
          menuSelectionEnabled
          anchorEl={copyToBoardAnchorEl}
          onCopy={(toRecipesBoardId, toMenuId) => {
            onCopyToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            setGlobalSnackbar({
              notificationText: 'Your meal has been copied to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          isMoveEnabled={!isReadOnly}
          onMove={(toRecipesBoardId, toMenuId) => {
            onMoveToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            setGlobalSnackbar({
              notificationText: 'Your meal has been moved to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          onDismiss={() => setCopyToBoardAnchorEl(null)}
          boardTitle="collection"
        />
      )}
    </CardActions>
  );
};

MealCardActions.propTypes = {
  recipesBoardId: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onQuickDuplicate: PropTypes.func.isRequired,
  onCopyToPlanner: PropTypes.func.isRequired,
  copyToBoardEnabled: PropTypes.bool.isRequired,
  onCopyToRecipesBoard: PropTypes.func.isRequired,
  organizeEnabled: PropTypes.bool.isRequired,
  onMoveToRecipesBoard: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isInMealBasket: PropTypes.bool.isRequired,
  onAddToMealBasket: PropTypes.func.isRequired,
  onRemoveFromMealBasket: PropTypes.func.isRequired,
  setGlobalSnackbar: PropTypes.func.isRequired,
  copyToProgrammeEnabled: PropTypes.bool.isRequired,
  destinationProgrammeIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCopyToProgramme: PropTypes.func.isRequired,
  quickDuplicateEnabled: PropTypes.bool.isRequired,
};

export default MealCardActions;
