import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Slider,
  TextField,
  useTheme,
} from '@mui/material';
import { mealTypeLabel } from '../../services/meal_types';
import MealAdvancedSearchCriteriaPerMealNutritionConstraints from './meal_advanced_search_criteria_per_meal_nutrition_constraints';
import { LOCALE_LABELS } from '../../services/locales';

const MIN_COOK_TIME = 0;
const MAX_COOK_TIME = 120;

const MealAdvancedSearchCriteria = ({
  searchCriteria,
  onChangeSearchCriteria,
  onShowResults,
  totalResults,
  totalResultsLoading,
  loadedResultsCount,
  moreAvailable,
  allIngredients,
  allTags,
  localeFilterEnabled,
}) => {
  const theme = useTheme();

  const isResultsCountExact = !moreAvailable;

  const disableIncludeIngredients = true;

  const safeSearchCriteria = {
    ...searchCriteria,
    includeIngredients: disableIncludeIngredients
      ? null
      : searchCriteria.includeIngredients,
  };

  const cookTimeRange = [MIN_COOK_TIME, MAX_COOK_TIME];
  if (safeSearchCriteria.cookTimeMin) {
    cookTimeRange[0] = safeSearchCriteria.cookTimeMin;
  }
  if (safeSearchCriteria.cookTimeMax) {
    cookTimeRange[1] = safeSearchCriteria.cookTimeMax;
  }

  const handleChangeCookTimeRange = (newCookTimeRange) => {
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      cookTimeMin: newCookTimeRange[0],
      cookTimeMax: newCookTimeRange[1],
    });
  };

  const availableIngredientsForExclude = allIngredients.filter(
    (ing) => !(safeSearchCriteria.excludeIngredients || []).includes(ing),
  );

  const handleRemoveExcludeIngredients = (ing) => {
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      excludeIngredients: (safeSearchCriteria.excludeIngredients || []).filter(
        (ing2) => ing2 !== ing,
      ),
    });
  };

  const handleToggleTag = (tag) => {
    let newTags = [...(safeSearchCriteria.tags || [])];
    if (newTags.includes(tag)) {
      newTags = newTags.filter((t) => t !== tag);
    } else {
      newTags = [...newTags, tag];
    }
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      tags: newTags,
    });
  };

  const availableIngredientsForInclude = allIngredients.filter(
    (ing) => !(safeSearchCriteria.includeIngredients || []).includes(ing),
  );

  const handleRemoveIncludeIngredients = (ing) => {
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      includeIngredients: (safeSearchCriteria.includeIngredients || []).filter(
        (ing2) => ing2 !== ing,
      ),
    });
  };

  const handleToggleMealType = (mealType) => {
    let newMealTypes = [...(safeSearchCriteria.mealTypes || [])];
    if (newMealTypes.includes(mealType)) {
      newMealTypes = newMealTypes.filter((mt) => mt !== mealType);
    } else {
      newMealTypes = [...newMealTypes, mealType];
    }
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      mealTypes: newMealTypes,
    });
  };

  const handleToggleDietaryPreference = (dietaryPreference) => {
    let newDietaryPreferences = [
      ...(safeSearchCriteria.dietaryPreferences || []),
    ];
    if (newDietaryPreferences.includes(dietaryPreference)) {
      newDietaryPreferences = newDietaryPreferences.filter(
        (dp) => dp !== dietaryPreference,
      );
    } else {
      newDietaryPreferences = [...newDietaryPreferences, dietaryPreference];
    }
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      dietaryPreferences: newDietaryPreferences,
    });
  };

  const handleChangePerMealNutritionConstraints = (
    newPerMealNutritionConstraints,
  ) => {
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      perMealNutritionConstraints: newPerMealNutritionConstraints,
    });
  };

  const handleChangeLocale = (newLocale) => {
    onChangeSearchCriteria({
      ...safeSearchCriteria,
      locales: [newLocale],
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <Grid
          container
          style={{
            maxHeight: 'calc(var(--app-height) - 195px)',
            overflow: 'auto',
            padding: 24,
            fontSize: 14,
          }}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              autoFocus
              value={safeSearchCriteria.searchString}
              placeholder="Search by name or ingredients"
              onChange={(ev) =>
                onChangeSearchCriteria({
                  ...safeSearchCriteria,
                  searchString: ev.target.value,
                })
              }
              sx={{
                '& input::placeholder': {
                  fontSize: '14px',
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ margin: '20px 0 10px', fontWeight: 'bold' }}>
            FILTER BY
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold', margin: '10px 0' }}>
            Meal type
          </Grid>
          <Grid item xs={12}>
            {['BREAKFAST', 'LUNCH', 'DINNER', 'SNACK'].map((mealType) => (
              <Chip
                key={mealType}
                tabIndex={-1}
                label={mealTypeLabel(mealType)}
                color="primary"
                variant={
                  (safeSearchCriteria.mealTypes || []).includes(mealType)
                    ? 'filled'
                    : 'outlined'
                }
                sx={{ margin: theme.spacing(0.5, 0.25) }}
                onClick={() => handleToggleMealType(mealType)}
              />
            ))}
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold', margin: '10px 0' }}>
            Dietary preferences
          </Grid>
          <Grid item xs={12}>
            {[
              'Gluten free',
              'Egg free',
              'Shellfish free',
              'Dairy free',
              'Nut free',
            ].map((dietaryPreference) => (
              <Chip
                key={dietaryPreference}
                tabIndex={-1}
                label={dietaryPreference}
                color="primary"
                variant={
                  (safeSearchCriteria.dietaryPreferences || []).includes(
                    dietaryPreference,
                  )
                    ? 'filled'
                    : 'outlined'
                }
                sx={{ margin: theme.spacing(0.5, 0.25) }}
                onClick={() => handleToggleDietaryPreference(dietaryPreference)}
              />
            ))}
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold', margin: '10px 0' }}>
            Exclude ingredients
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="excludes-standard"
              freeSolo
              disableClearable
              filterSelectedOptions
              options={availableIngredientsForExclude}
              onChange={(e, newValues, reason) => {
                onChangeSearchCriteria({
                  ...safeSearchCriteria,
                  excludeIngredients: newValues,
                });
              }}
              renderTags={() => {}}
              value={safeSearchCriteria.excludeIngredients || []}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="standard"
                  placeholder="Type an ingredient"
                  margin="normal"
                  sx={{
                    '& input::placeholder': {
                      fontSize: '14px',
                    },
                  }}
                />
              )}
            />
            <div className="selectedTags">
              {(safeSearchCriteria.excludeIngredients || []).map((ing) => {
                // This is to handle new options added by the user (allowed by freeSolo prop).
                return (
                  <Chip
                    key={ing}
                    tabIndex={-1}
                    label={ing}
                    sx={{ margin: theme.spacing(0.5, 0.25) }}
                    style={{
                      backgroundColor: 'var(--app-primary-color)',
                      color: 'var(--app-primary-font-color)',
                    }}
                    onDelete={() => handleRemoveExcludeIngredients(ing)}
                  />
                );
              })}
            </div>
          </Grid>
          {allTags.length > 0 && (
            <>
              <Grid
                item
                xs={12}
                style={{ fontWeight: 'bold', margin: '10px 0' }}>
                Category
              </Grid>
              <Grid item xs={12}>
                {allTags.map((tag) => (
                  <Chip
                    key={tag}
                    tabIndex={-1}
                    label={tag}
                    color="primary"
                    variant={
                      (safeSearchCriteria.tags || []).includes(tag)
                        ? 'filled'
                        : 'outlined'
                    }
                    sx={{ margin: theme.spacing(0.5, 0.25) }}
                    onClick={() => handleToggleTag(tag)}
                  />
                ))}
              </Grid>
            </>
          )}
          {!disableIncludeIngredients && (
            <>
              <Grid
                item
                xs={12}
                style={{ fontWeight: 'bold', margin: '10px 0' }}>
                Include ingredients
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  freeSolo
                  disableClearable
                  filterSelectedOptions
                  options={availableIngredientsForInclude}
                  onChange={(e, newValues, reason) => {
                    onChangeSearchCriteria({
                      ...safeSearchCriteria,
                      includeIngredients: newValues,
                    });
                  }}
                  renderTags={() => {}}
                  value={safeSearchCriteria.includeIngredients || []}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="standard"
                      placeholder="Type an ingredient"
                      margin="normal"
                      sx={{
                        '& input::placeholder': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  )}
                />
                <div className="selectedTags">
                  {(safeSearchCriteria.includeIngredients || []).map((ing) => {
                    // This is to handle new options added by the user (allowed by freeSolo prop).
                    return (
                      <Chip
                        key={ing}
                        tabIndex={-1}
                        label={ing}
                        sx={{ margin: theme.spacing(0.5, 0.25) }}
                        style={{
                          backgroundColor: 'var(--app-primary-color)',
                          color: 'var(--app-primary-font-color)',
                        }}
                        onDelete={() => handleRemoveIncludeIngredients(ing)}
                      />
                    );
                  })}
                </div>
              </Grid>
            </>
          )}
          <Grid item xs={12} style={{ fontWeight: 'bold', margin: '10px 0' }}>
            Cooking time (minutes)
          </Grid>
          <Grid item xs={12} style={{ padding: '0 20px' }}>
            <Slider
              value={cookTimeRange}
              min={MIN_COOK_TIME}
              max={MAX_COOK_TIME}
              step={5}
              onChange={(ev, values) => handleChangeCookTimeRange(values)}
              valueLabelDisplay="auto"
            />
          </Grid>
          {localeFilterEnabled && (
            <>
              <Grid
                item
                xs={12}
                style={{ fontWeight: 'bold', margin: '10px 0' }}>
                Filter by locale
              </Grid>
              <Grid item xs={12}>
                <Select
                  variant="standard"
                  fullWidth
                  displayEmpty
                  value={safeSearchCriteria.locales[0]}
                  onChange={(ev) => {
                    const newValue = ev.target.value;
                    handleChangeLocale(newValue);
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  {['en-US', 'en-GB'].map((locale) => (
                    <MenuItem key={locale} value={locale}>
                      {LOCALE_LABELS[locale]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </>
          )}
          <Grid item xs={12} style={{ fontWeight: 'bold', margin: '10px 0' }}>
            Apply per meal nutrition constraints
          </Grid>
          <MealAdvancedSearchCriteriaPerMealNutritionConstraints
            perMealNutritionConstraints={
              safeSearchCriteria.perMealNutritionConstraints
            }
            onChange={handleChangePerMealNutritionConstraints}
          />
        </Grid>
      </div>
      <div style={{ flexGrow: 0 }}>
        <Grid
          container
          style={{
            padding: 24,
            fontSize: 14,
            borderTop: '1px solid #e1e1e1',
          }}>
          <Grid item xs={6}>
            {totalResultsLoading && (
              <CircularProgress size={20} style={{ margin: 'auto 10px' }} />
            )}
            {!totalResultsLoading && (
              <>
                <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                  {isResultsCountExact
                    ? loadedResultsCount
                    : `More than ${loadedResultsCount}`}
                </div>
                <div style={{ fontSize: 14 }}>recipes available</div>
              </>
            )}
          </Grid>
          <Grid item xs={6} style={{ fontWeight: 'bold' }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={onShowResults}>
                Show recipes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

MealAdvancedSearchCriteria.propTypes = {
  searchCriteria: PropTypes.shape({
    searchString: PropTypes.string,
    cookTimeMin: PropTypes.number,
    cookTimeMax: PropTypes.number,
    mealTypes: PropTypes.arrayOf(PropTypes.string),
    excludeIngredients: PropTypes.arrayOf(PropTypes.string),
    includeIngredients: PropTypes.arrayOf(PropTypes.string),
    dietaryPreferences: PropTypes.arrayOf(PropTypes.string),
    perMealNutritionConstraints: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onChangeSearchCriteria: PropTypes.func.isRequired,
  onShowResults: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  totalResultsLoading: PropTypes.bool.isRequired,
  loadedResultsCount: PropTypes.number.isRequired,
  moreAvailable: PropTypes.bool.isRequired,
  allIngredients: PropTypes.arrayOf(PropTypes.string).isRequired,
  allTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  localeFilterEnabled: PropTypes.bool,
};

MealAdvancedSearchCriteria.defaultProps = {
  localeFilterEnabled: false,
};

export default MealAdvancedSearchCriteria;
