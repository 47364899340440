import {
  plannerWeekWithMealAdded,
  plannerWeekWithMealMoved,
  plannerWeekWithObjectRemoved,
  plannerWeekWithNoteAdded,
  daysArrayIndex,
  dbWeekStartDatesAndDayIndexesCoveringViewWeek,
  dayIndexFromArrayIndex,
  dbWeekStartDatesCoveringViewWeek,
  plannerEntriesIndexOfObject,
  plannerWeekWithMealReplaced,
  plannerWeekWithContentEntryAdded,
} from '../services/planner';
import {
  mealsWithMealsRemoved,
  mealsWithMealsUpdated,
} from '../services/meals';
import {
  notesWithNotesRemoved,
  notesWithNotesUpdated,
} from '../services/notes';
import { contentEntriesWithContentEntriesUpdated } from '../services/content_entries';
import { PlannerEntryType } from '../API';

// eslint-disable-next-line import/prefer-default-export
export const plannerReducer = (state, action) => {
  /*
    This function (> 280 lines) is slow to compile !
    Some syntax seems to cause compilation time to be quadratic
   */

  switch (action.type) {
    case 'PLANNER_NETWORK_STATE_CHANGED': {
      return {
        ...state,
        plannerNetworkState: action.networkState,
      };
    }

    case 'PLANNER_WEEK_UPDATED_FROM_BACKEND': {
      const { plannerWeek } = action;
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerWeek.startDate]: {
            ...state.plannerWeeks[plannerWeek.startDate],
            days: plannerWeek.days,
          },
        },
      };
    }

    case 'PLANNER_WEEK_AVAILABLE': {
      const { plannerWeek, meals, notes, contentEntries } = action;
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerWeek.startDate]: plannerWeek,
        },
        meals: {
          ...state.meals,
          ...meals,
        },
        notes: {
          ...state.notes,
          ...notes,
        },
        contentEntries: {
          ...state.contentEntries,
          ...contentEntries,
        },
      };
    }

    case 'CURRENT_PLANNER_VIEW_WEEK_START_DATE_CHANGED': {
      const { startDate } = action;
      return {
        ...state,
        currentPlannerViewWeekStartDate: startDate,
      };
    }

    case 'NEW_MEAL_ADDED_TO_PLANNER': {
      const { plannerDbWeekStartDate, dayIndex, insertedMeal } = action;
      // TODO extract into service and add test
      const updatedPlannerWeek = plannerWeekWithMealAdded(
        state.plannerWeeks[plannerDbWeekStartDate],
        insertedMeal,
        dayIndex,
      );
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
        meals: mealsWithMealsUpdated(state.meals, [insertedMeal]),
      };
    }

    case 'PLANNER_MEAL_REPLACED': {
      const { plannerDbWeekStartDate, dayIndex, originalMealID, newMeal } =
        action;
      const updatedPlannerWeek = originalMealID
        ? plannerWeekWithMealReplaced(
            state.plannerWeeks[plannerDbWeekStartDate],
            originalMealID,
            newMeal,
            dayIndex,
          )
        : plannerWeekWithMealAdded(
            state.plannerWeeks[plannerDbWeekStartDate],
            newMeal,
            dayIndex,
          );
      const mealsWithOriginalMealRemoved = originalMealID
        ? mealsWithMealsRemoved(state.meals, [originalMealID])
        : state.meals;
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
        meals: mealsWithMealsUpdated(mealsWithOriginalMealRemoved, [newMeal]),
      };
    }

    case 'NEW_NOTE_ADDED_TO_PLANNER': {
      const { plannerDbWeekStartDate, dayIndex, insertedNote } = action;
      // TODO extract into service and add test
      const updatedPlannerWeek = plannerWeekWithNoteAdded(
        state.plannerWeeks[plannerDbWeekStartDate],
        insertedNote,
        dayIndex,
      );
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
        notes: notesWithNotesUpdated(state.notes, [insertedNote]),
      };
    }

    case 'NEW_CONTENT_ENTRY_ADDED_TO_PLANNER': {
      const { plannerDbWeekStartDate, dayIndex, insertedContentEntry } = action;
      // TODO extract into service and add test
      const updatedPlannerWeek = plannerWeekWithContentEntryAdded(
        state.plannerWeeks[plannerDbWeekStartDate],
        insertedContentEntry.id,
        dayIndex,
      );
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          [insertedContentEntry],
        ),
      };
    }

    case 'NEW_NOTE_ADDED_TO_PLANNER_AT_POSITION': {
      const { plannerDbWeekStartDate, dayIndex, position, insertedNote } =
        action;
      const updatedPlannerWeek = plannerWeekWithNoteAdded(
        state.plannerWeeks[plannerDbWeekStartDate],
        insertedNote,
        dayIndex,
        position,
      );
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
        notes: notesWithNotesUpdated(state.notes, [insertedNote]),
      };
    }

    case 'NEW_MEAL_ADDED_TO_PLANNER_AT_POSITION': {
      const { plannerDbWeekStartDate, dayIndex, position, insertedMeal } =
        action;
      const updatedPlannerWeek = plannerWeekWithMealAdded(
        state.plannerWeeks[plannerDbWeekStartDate],
        insertedMeal,
        dayIndex,
        position,
      );
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
        meals: mealsWithMealsUpdated(state.meals, [insertedMeal]),
      };
    }

    case 'PLANNER_ENTRY_MOVED_SAME_DB_WEEK': {
      const {
        objectID,
        plannerDbWeekStartDate,
        sourceDayIndex,
        targetDayIndex,
        position,
      } = action;
      const plannerWeek = state.plannerWeeks[plannerDbWeekStartDate];
      const updatedPlannerWeek = plannerWeekWithMealMoved(
        plannerWeek,
        objectID,
        sourceDayIndex,
        targetDayIndex,
        position,
      );
      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
      };
    }

    case 'PLANNER_ENTRY_MOVED': {
      const {
        objectID,
        entryType,
        sourceDbWeekStartDate,
        sourceDayIndex,
        targetDbWeekStartDate,
        targetDayIndex,
        position,
      } = action;
      const sourcePlannerWeek = state.plannerWeeks[sourceDbWeekStartDate];
      const updatedSourcePlannerWeek = plannerWeekWithObjectRemoved(
        sourcePlannerWeek,
        objectID,
        sourceDayIndex,
      );
      const targetPlannerWeek = state.plannerWeeks[targetDbWeekStartDate];
      const updatedTargetPlannerWeek =
        entryType === 'NOTE'
          ? plannerWeekWithNoteAdded(
              targetPlannerWeek,
              state.notes[objectID],
              targetDayIndex,
              position,
            )
          : plannerWeekWithMealAdded(
              targetPlannerWeek,
              state.meals[objectID],
              targetDayIndex,
              position,
            );

      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [sourceDbWeekStartDate]: updatedSourcePlannerWeek,
          [targetDbWeekStartDate]: updatedTargetPlannerWeek,
        },
      };
    }

    case 'PLANNER_ENTRY_DELETED': {
      const {
        plannerViewWeekStartDate,
        plannerDbWeekStartDate,
        objectID,
        dayIndex,
      } = action;
      const plannerEntryType = plannerEntryTypeSelector(
        state,
        plannerViewWeekStartDate,
        objectID,
      );
      const updatedPlannerWeek = plannerWeekWithObjectRemoved(
        state.plannerWeeks[plannerDbWeekStartDate],
        objectID,
        dayIndex,
      );
      const updatedPlannerWeeks = {
        ...state.plannerWeeks,
        [plannerDbWeekStartDate]: updatedPlannerWeek,
      };
      if (plannerEntryType === 'NOTE') {
        return {
          ...state,
          plannerWeeks: updatedPlannerWeeks,
          notes: notesWithNotesRemoved(state.notes, [objectID]),
        };
      }
      return {
        ...state,
        plannerWeeks: updatedPlannerWeeks,
        meals: mealsWithMealsRemoved(state.meals, [objectID]),
      };
    }

    case 'PLANNER_WEEK_OF_ENTRIES_ADDED': {
      const { itemCopyRecords } = action;
      const updatedPlannerWeeks = { ...state.plannerWeeks };
      itemCopyRecords.forEach((itemCopyRecord) => {
        let updatedPlannerWeek =
          updatedPlannerWeeks[itemCopyRecord.destinationPlannerDbWeekStartDate];
        if (itemCopyRecord.plannerEntryType === 'NOTE') {
          updatedPlannerWeek = plannerWeekWithNoteAdded(
            updatedPlannerWeek,
            itemCopyRecord.destinationObject,
            itemCopyRecord.dayIndex,
          );
        } else {
          updatedPlannerWeek = plannerWeekWithMealAdded(
            updatedPlannerWeek,
            itemCopyRecord.destinationObject,
            itemCopyRecord.dayIndex,
          );
        }
        updatedPlannerWeeks[itemCopyRecord.destinationPlannerDbWeekStartDate] =
          updatedPlannerWeek;
      });
      const addedMeals = itemCopyRecords
        .filter(
          (itemCopyRecord) =>
            !itemCopyRecord.plannerEntryType ||
            itemCopyRecord.plannerEntryType === 'MEAL',
        )
        .map((itemCopyRecord) => itemCopyRecord.destinationObject);
      const addedNotes = itemCopyRecords
        .filter((itemCopyRecord) => itemCopyRecord.plannerEntryType === 'NOTE')
        .map((itemCopyRecord) => itemCopyRecord.destinationObject);
      return {
        ...state,
        plannerWeeks: updatedPlannerWeeks,
        meals: mealsWithMealsUpdated(state.meals, addedMeals),
        notes: notesWithNotesUpdated(state.notes, addedNotes),
      };
    }

    case 'PLANNER_DAY_ADDED_TO_MEAL_BASKET': {
      const { plannerDbWeekStartDate, dayIndex } = action;
      const plannerDay =
        state.plannerWeeks[plannerDbWeekStartDate].days[
          daysArrayIndex(dayIndex)
        ];
      const mealIDs = plannerDay.entries.map((e) => e.mealID);
      const newMealIDsInBasket = [...state.mealBasket.mealIDs];
      mealIDs.forEach((mealId) => {
        if (
          !newMealIDsInBasket.includes(mealId) &&
          Object.keys(state.meals).includes(mealId)
        ) {
          newMealIDsInBasket.push(mealId);
        }
      });
      return {
        ...state,
        mealBasket: {
          ...state.mealBasket,
          mealIDs: newMealIDsInBasket,
        },
      };
    }

    case 'PLANNER_DAY_OBJECTS_DELETED': {
      const { plannerDbWeekStartDate, dayIndex } = action;
      const plannerDay =
        state.plannerWeeks[plannerDbWeekStartDate].days[
          daysArrayIndex(dayIndex)
        ];
      const mealIdsToRemove = plannerDay.entries
        .filter((e) => !e.plannerEntryType || e.plannerEntryType === 'MEAL')
        .map((e) => e.mealID);
      const noteIdsToRemove = plannerDay.entries
        .filter((e) => e.plannerEntryType === 'NOTE')
        .map((e) => e.objectID);
      const newMeals = mealsWithMealsRemoved(state.meals, mealIdsToRemove);
      const newNotes = notesWithNotesRemoved(state.notes, noteIdsToRemove);
      const updatedPlannerWeek = {
        ...state.plannerWeeks[plannerDbWeekStartDate],
      };
      updatedPlannerWeek.days[daysArrayIndex(dayIndex)] = {
        ...updatedPlannerWeek.days[daysArrayIndex(dayIndex)],
        entries: [],
      };

      return {
        ...state,
        plannerWeeks: {
          ...state.plannerWeeks,
          [plannerDbWeekStartDate]: updatedPlannerWeek,
        },
        meals: newMeals,
        notes: newNotes,
      };
    }

    case 'ADD_TO_PLANNER_DESTINATION_CHANGED': {
      const { destination } = action;
      return {
        ...state,
        currentAddToPlannerDestination: destination,
      };
    }

    case 'ADD_TO_PLANNER_DESTINATION_PLAN_ID_CHANGED': {
      const { destinationPlanId } = action;
      return {
        ...state,
        currentAddToPlannerDestinationPlanId: destinationPlanId,
      };
    }

    case 'PLANNER_EXTENDED_WITH_RECOMMENDED_ENTRIES': {
      /*
Structure of recommendedMenuWithPlannerEntries: 
{
  days: {
    plannerDbWeekStartDate,
    dayIndex, // The day index in the DB week!
    entries: [ // the NEW entries to be added

    ]
  }
}
      */
      const {
        recommendedMenuWithPlannerEntries,
        referencedMeals,
        referencedContentEntries,
      } = action;
      console.log(JSON.stringify(recommendedMenuWithPlannerEntries));
      const updatedPlannerWeeks = { ...state.plannerWeeks };
      recommendedMenuWithPlannerEntries.days.forEach(
        (recommendedDayWithPlannerEntries) => {
          let updatedPlannerWeek =
            updatedPlannerWeeks[
              recommendedDayWithPlannerEntries.plannerDbWeekStartDate
            ];
          recommendedDayWithPlannerEntries.entries.forEach((entry) => {
            if (entry.plannerEntryType === PlannerEntryType.MEAL) {
              updatedPlannerWeek = plannerWeekWithMealAdded(
                updatedPlannerWeek,
                { id: entry.mealID },
                recommendedDayWithPlannerEntries.dayIndex,
              );
            } else if (
              entry.plannerEntryType === PlannerEntryType.CONTENT_ENTRY
            ) {
              updatedPlannerWeek = plannerWeekWithContentEntryAdded(
                updatedPlannerWeek,
                entry.objectID,
                recommendedDayWithPlannerEntries.dayIndex,
              );
            }
          });
          updatedPlannerWeeks[
            recommendedDayWithPlannerEntries.plannerDbWeekStartDate
          ] = updatedPlannerWeek;
        },
      );
      return {
        ...state,
        plannerWeeks: updatedPlannerWeeks,
        meals: mealsWithMealsUpdated(state.meals, referencedMeals),
        contentEntries: contentEntriesWithContentEntriesUpdated(
          state.contentEntries,
          referencedContentEntries,
        ),
      };
    }

    default:
      return state;
  }
};

/**
 * returns array of {dayIndex, parentBoardId, entries}
 */
export const daysForPlannerBoardSelector = (
  state,
  plannerViewWeekStartDate,
) => {
  const startDatesAndDayIndexes = dbWeekStartDatesAndDayIndexesCoveringViewWeek(
    plannerViewWeekStartDate,
  );
  return startDatesAndDayIndexes.map((sdi) => {
    if (
      !Object.prototype.hasOwnProperty.call(
        state.plannerWeeks,
        sdi.dbWeekStartDate,
      )
    ) {
      return { dayIndex: sdi.dayIndex };
    }
    return {
      dayIndex: sdi.dayIndex,
      plannerDbWeekStartDate: sdi.dbWeekStartDate,
      parentBoardId: state.plannerWeeks[sdi.dbWeekStartDate].id,
      entries:
        state.plannerWeeks[sdi.dbWeekStartDate].days[
          daysArrayIndex(sdi.dayIndex)
        ].entries,
    };
  });
};

export const mealsForPlannerBoardSelector = (
  state,
  plannerViewWeekStartDate,
) => {
  const plannerWeekDays = daysForPlannerBoardSelector(
    state,
    plannerViewWeekStartDate,
  );
  const mealIDs = plannerWeekDays.flatMap((d) =>
    (d.entries || []).map((e) => e.mealID),
  );
  const meals = {};
  mealIDs.forEach((mealID) => {
    meals[mealID] = state.meals[mealID];
  });
  return meals;
};

export const notesForPlannerBoardSelector = (
  state,
  plannerViewWeekStartDate,
) => {
  const plannerWeekDays = daysForPlannerBoardSelector(
    state,
    plannerViewWeekStartDate,
  );
  const objectIDs = plannerWeekDays.flatMap((d) =>
    (d.entries || [])
      .filter((e) => e.plannerEntryType === PlannerEntryType.NOTE)
      .map((e) => e.objectID),
  );
  const notes = {};
  objectIDs.forEach((objectID) => {
    notes[objectID] = state.notes[objectID];
  });
  return notes;
};

export const contentEntriesForPlannerBoardSelector = (
  state,
  plannerViewWeekStartDate,
) => {
  const plannerWeekDays = daysForPlannerBoardSelector(
    state,
    plannerViewWeekStartDate,
  );
  const objectIDs = plannerWeekDays.flatMap((d) =>
    (d.entries || [])
      .filter((e) => e.plannerEntryType === PlannerEntryType.CONTENT_ENTRY)
      .map((e) => e.objectID),
  );
  const contentEntries = {};
  objectIDs.forEach((objectID) => {
    contentEntries[objectID] = state.contentEntries[objectID];
  });
  return contentEntries;
};

export const findPlannerEntry = (plannerWeeks, dbWeekStartDates, objectID) => {
  let plannerDbWeekStartDate = null;
  let plannerWeek = null;
  let dayIndex = null;
  let plannerEntry = null;
  dbWeekStartDates.forEach((dbWeekStartDate) => {
    if (!Object.prototype.hasOwnProperty.call(plannerWeeks, dbWeekStartDate)) {
      return;
    }
    const foundPlannerWeek = plannerWeeks[dbWeekStartDate];
    const foundDayArrayIndex = foundPlannerWeek.days.findIndex(
      (plannerDay) =>
        plannerEntriesIndexOfObject(plannerDay.entries, objectID) !== -1,
    );
    if (foundDayArrayIndex !== -1) {
      const plannerEntryIndex = plannerEntriesIndexOfObject(
        foundPlannerWeek.days[foundDayArrayIndex].entries,
        objectID,
      );
      plannerDbWeekStartDate = dbWeekStartDate;
      plannerWeek = foundPlannerWeek;
      dayIndex = dayIndexFromArrayIndex(foundDayArrayIndex);
      plannerEntry =
        foundPlannerWeek.days[foundDayArrayIndex].entries[plannerEntryIndex];
    }
  });
  return { plannerDbWeekStartDate, plannerWeek, dayIndex, plannerEntry };
};

export const plannerEntryType = (
  plannerWeeks,
  plannerViewWeekStartDate,
  objectId,
) => {
  const dbWeekStartDates = dbWeekStartDatesCoveringViewWeek(
    plannerViewWeekStartDate,
  );
  const { plannerEntry: foundEntry } = findPlannerEntry(
    plannerWeeks,
    dbWeekStartDates,
    objectId,
  );
  return foundEntry?.plannerEntryType;
};

export const plannerEntryTypeSelector = (
  state,
  plannerViewWeekStartDate,
  objectId,
) => {
  return (
    objectId &&
    plannerEntryType(state.plannerWeeks, plannerViewWeekStartDate, objectId)
  );
};

export const mealCardsForPlannerBoardSelector = (
  state,
  plannerViewWeekStartDate,
) => {
  const meals = mealsForPlannerBoardSelector(state, plannerViewWeekStartDate);
  Object.keys(meals).forEach((mealId) => {
    if (meals[mealId]) {
      meals[mealId] = {
        id: mealId,
        recipes: [
          {
            title: meals[mealId].recipes[0].title,
            mealTypes: meals[mealId].recipes[0].mealTypes,
          },
        ],
        derivedNutrition: meals[mealId].derivedNutrition,
      };
    }
  });
  return meals;
};
