import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

const MyDayContentView = () => {
  const { sharedContentEntryID } = useParams();
  const navigate = useNavigate();

  const contentEntry = useSelector(
    (state) =>
      state.sharedContentEntries[sharedContentEntryID] ||
      state.contentEntries[sharedContentEntryID],
  );

  const onBack = () => {
    navigate(-1);
  };

  return (
    <div className="contentPage">
      <Grid
        container
        alignContent="start"
        style={{
          height: 'calc(var(--app-height) - 55px)',
          overflowY: 'scroll',
        }}>
        <Grid
          item
          xs={12}
          style={{
            position: 'relative',
          }}>
          {contentEntry.isCoverImageShownInDetailView && (
            <div>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                src={contentEntry.coverImageUrl}
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  minHeight: '50px',
                }}
              />
            </div>
          )}
          {!contentEntry.isCoverImageShownInDetailView && (
            <div style={{ height: '50px' }} />
          )}
          <IconButton
            aria-label="back"
            onClick={onBack}
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              backgroundColor: 'var(--app-primary-color)',
              color: 'white',
            }}>
            <ArrowBackIosOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} className="programmeDescription light-background">
          <div style={{ padding: '15px 15px 0' }}>
            <div
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: contentEntry.body,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyDayContentView;
