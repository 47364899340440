import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  menuAndMealFor,
  recipesBoardByIdSelector,
  sharedMenuAndMealFor,
  endUserSpaceAssignedSharedBoardIDsSelector,
} from '../../reducers/recipes_reducer';
import {
  getEditEventsForUndoIngredientEditOperation,
  getSuggestionsFromHistoryOperation,
  getSuggestionsOperation,
} from '../../operations/meal_operations';
import { addedItems } from '../../services/arrays';
import { tagsUsedAction } from '../../action_creators/user_action_creators';
import {
  locallyScaleSharedMealAction,
  mealArraySectionItemAddedAction,
  mealArraySectionItemDeletedAction,
  mealArraySectionItemsPastedAction,
  mealArraySectionItemUpdatedAction,
  mealImageUpdatedAction,
  mealIngredientCheckStateChangeAction,
  mealIngredientScalingRuleUpdatedAction,
  mealSingleSectionUpdatedAction,
  mealTagsChangedAction,
  scaleMealAction,
  setMealAddonEnabledAction,
  sharedMealIngredientCheckStateChangeAction,
  syncMealAction,
  updateFoodBrainDerivedDataAction,
} from '../../action_creators/meal_action_creators';
import {
  isUserSpaceMembershipDefaultSpaceSelector,
  userIsCreatorSelector,
  userLocaleSelector,
} from '../../reducers/user_reducer';
import { uploadUserImage } from '../../services/user_generated_content';
import { ContainerType } from '../../API';
import MealDetailModal from './meal_detail_modal';
import { isProductizedSharedBoardID } from '../../services/productized_shared_boards';

const MealDetailContainer = ({
  parentSmorgBoardId,
  mealId,
  menuId,
  visible,
  // isPreview,
  dismiss,
}) => {
  // Must not access state from within callbacks passed to ContentEditable.
  // The issue is described at:
  // https://github.com/lovasoa/react-contenteditable/issues/161
  const currentTextRef = useRef();
  const currentRecipeRef = useRef();
  const locale = useSelector(userLocaleSelector);

  const spaceAssignedSharedBoardIDs = useSelector(
    endUserSpaceAssignedSharedBoardIDsSelector,
  );

  const isCreator = useSelector(userIsCreatorSelector);

  const [currentlyEditingInline, setCurrentlyEditingInline] = useState({
    section: null,
    itemId: null,
  });

  const dispatch = useDispatch();

  const recipesBoard = useSelector((state) =>
    recipesBoardByIdSelector(state, parentSmorgBoardId),
  );

  const isSharedProgrammeBoard = [
    ContainerType.PROGRAMME,
    ContainerType.SHARED_PROGRAMME,
  ].includes(recipesBoard?.embeddedInContainerType);

  const isProductizedSharedBoard =
    spaceAssignedSharedBoardIDs.includes(parentSmorgBoardId) ||
    isProductizedSharedBoardID(parentSmorgBoardId);

  const isReadOnly =
    !isCreator &&
    (isSharedProgrammeBoard || isProductizedSharedBoard);

  const meals = useSelector(
    (state) => state.meals,
    // mealsForRecipesBoardSelector(state, parentSmorgBoardId),
  );

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const updateFoodBrainDerivedData = useCallback(async () => {
    try {
      await dispatch(updateFoodBrainDerivedDataAction(mealId));
    } finally {
      await dispatch(syncMealAction(mealId));
    }
  }, [dispatch, mealId]);

  // eslint-disable-next-line no-nested-ternary
  let meal = null;
  let menu = null;
  let isSharedMeal = null;
  if (visible) {
    const mealLookupResult = menuAndMealFor(
      recipesBoard,
      meals,
      mealId,
      menuId,
    );
    if (mealLookupResult.meal) {
      menu = mealLookupResult.menu;
      meal = mealLookupResult.meal;
      isSharedMeal = false;
    } else {
      const sharedMealLookupResult = sharedMenuAndMealFor(
        recipesBoard,
        sharedMeals,
        mealId,
        menuId,
      );
      menu = sharedMealLookupResult.menu;
      meal = sharedMealLookupResult.meal;
      isSharedMeal = true;
    }
  }

  const recipe = meal && meal.recipes[0];

  const [scaleToServings, setScaleToServings] = useState(recipe?.servings);
  const [savingInProgress, setSavingInProgress] = useState(false);

  const dismissEditingInline = () => {
    currentTextRef.current = null;
    setCurrentlyEditingInline({ section: null, itemId: null });
  };

  useEffect(() => {
    currentRecipeRef.current = recipe;
    setScaleToServings(recipe?.servings);
    setSavingInProgress(false);
  }, [recipe]);

  useEffect(() => {
    if (!mealId) {
      return;
    }
    dismissEditingInline();
    updateFoodBrainDerivedData();
  }, [mealId, updateFoodBrainDerivedData]);

  const onIngredientsUpdate = () => {
    updateFoodBrainDerivedData();
  };

  const [
    ingredientSuggestionsNetworkState,
    setIngredientSuggestionsNetworkState,
  ] = useState({ loading: false, loaded: false, error: null });

  const [ingredientSuggestions, setIngredientSuggestions] = useState([]);

  if (!visible || !meal) {
    return null;
  }

  console.log(`MealDetail mealId=${mealId}, menuId=${menuId}`);

  const onInlineEditBlur = async (section, itemId, reason) => {
    console.log(`Blur ${section} ${itemId} ${reason}`);
    let shouldDismissEditingInline = true;
    if (
      [
        'title',
        'shortDescription',
        'chef',
        'servings',
        'prepTime',
        'cookTime',
        'mealTypes',
        'utensils',
        'recipeNotes',
      ].includes(section)
    ) {
      const contentChanged =
        currentTextRef.current !== null &&
        currentTextRef.current !== undefined &&
        currentTextRef.current !== currentRecipeRef.current[section];
      if (contentChanged) {
        await dispatch(
          mealSingleSectionUpdatedAction(
            mealId,
            section,
            currentTextRef.current,
          ),
        );
        if (section === 'servings') {
          // Nutrition per serving needs to be recomputed
          await updateFoodBrainDerivedData();
        }
      }
    } else if (section === 'ingredients') {
      const { ingredients } = meal.recipes[0];
      const index = ingredients.findIndex((ing) => ing.id === itemId);
      console.log(
        `Reason is ${reason}, editing item at index ${index} out of ${ingredients.length}`,
      );
      const editMethod =
        reason === 'selectOption' ? 'OPTION_SELECTED' : 'TEXT_TYPED';
      const pastedRows = currentTextRef.current
        .split('\n')
        .filter((row) => !!row);
      const appendNewItem =
        reason === 'enter-key' && index === ingredients.length - 1;
      const appendedNewItemId = appendNewItem && uuid();
      if (pastedRows.length >= 2) {
        console.log(`Detected paste, ${pastedRows.length} rows`);
        await dispatch(
          mealArraySectionItemsPastedAction(
            mealId,
            section,
            itemId,
            pastedRows,
          ),
        );
      } else {
        console.log({ appendedNewItemId });
        await dispatch(
          mealArraySectionItemUpdatedAction(
            mealId,
            section,
            itemId,
            currentTextRef.current,
            appendedNewItemId,
            editMethod,
          ),
        );
      }
      await onIngredientsUpdate();
      if (appendNewItem) {
        resetSuggestions();
        currentTextRef.current = '';
        focusOnField(section, appendedNewItemId);
        shouldDismissEditingInline = false;
      }
    } else if (section === 'preparations') {
      const pastedRows = currentTextRef.current
        .split('\n')
        .filter((row) => !!row);
      if (pastedRows.length >= 2) {
        console.log(`Detected paste, ${pastedRows.length} rows`);
        dispatch(
          mealArraySectionItemsPastedAction(
            mealId,
            section,
            itemId,
            pastedRows,
          ),
        );
      } else {
        dispatch(
          mealArraySectionItemUpdatedAction(
            mealId,
            section,
            itemId,
            currentTextRef.current,
          ),
        );
      }
    }

    if (shouldDismissEditingInline) {
      dismissEditingInline();
    }
  };

  const focusOnField = (section, id) => {
    setCurrentlyEditingInline({ section, itemId: id });
    window.setTimeout(() => {
      const el = document.getElementById(`${section}-${id}`);
      if (el) {
        el.focus();
      }
    }, 100);
  };

  const onAddArraySectionItem = (section) => {
    const addedIndex = (meal.recipes[0][section] || []).length;
    const newId = uuid();
    dispatch(
      mealArraySectionItemAddedAction(mealId, section, addedIndex, newId, ''),
    );
    resetSuggestions();
    focusOnField(section, newId);
  };

  const onRemoveArraySectionItem = async (section, itemId) => {
    await dispatch(mealArraySectionItemDeletedAction(mealId, section, itemId));
    if (section === 'ingredients') {
      await onIngredientsUpdate();
    }
  };

  const onIngredientCheckChange = (ingredientId, checkState) => {
    if (isSharedMeal) {
      dispatch(
        sharedMealIngredientCheckStateChangeAction(
          mealId,
          ingredientId,
          checkState,
        ),
      );
    } else {
      dispatch(
        mealIngredientCheckStateChangeAction(mealId, ingredientId, checkState),
      );
    }
  };

  const loadSuggestions = async (ingredient) => {
    setIngredientSuggestions([]);
    setIngredientSuggestionsNetworkState({ loading: true });
    try {
      await Promise.all([
        loadSuggestionsForUndo(mealId, ingredient.id),
        loadSuggestionsFromHistory(ingredient),
        loadSuggestionsFromFoodBrain(ingredient),
      ]);
      setIngredientSuggestionsNetworkState({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setIngredientSuggestionsNetworkState({
        loading: false,
        loaded: false,
        error: e.toString(),
      });
    }
  };

  const loadSuggestionsForUndo = async (mealID, ingredientID) => {
    const editEvents = await getEditEventsForUndoIngredientEditOperation(
      mealID,
      ingredientID,
    );
    const undoSuggestions = editEvents.map((editEvent) => ({
      suggestionText: editEvent.oldValue,
      suggestionType: 'undo',
      highlightTokens: [],
    }));
    setIngredientSuggestions((prevIngredientSuggestions) => [
      ...prevIngredientSuggestions,
      ...undoSuggestions,
    ]);
  };

  const loadSuggestionsFromHistory = async (ingredient) => {
    const ingredientEditSuggestions = await getSuggestionsFromHistoryOperation(
      ingredient.id,
      ingredient.fullText,
    );
    const historySuggestions = ingredientEditSuggestions.map(
      (ingredientEditSuggestion) => ({
        suggestionText: ingredientEditSuggestion.suggestion,
        suggestionType: 'history',
        highlightTokens: [],
      }),
    );
    setIngredientSuggestions((prevIngredientSuggestions) => [
      ...prevIngredientSuggestions,
      ...historySuggestions,
    ]);
  };

  const loadSuggestionsFromFoodBrain = async (ingredient) => {
    const suggestionsResponse = await getSuggestionsOperation(
      [ingredient],
      locale,
    );
    setIngredientSuggestions((prevIngredientSuggestions) => [
      ...prevIngredientSuggestions,
      ...suggestionsResponse[0].suggestions,
    ]);
  };

  const resetSuggestions = () => {
    setIngredientSuggestions([]);
    setIngredientSuggestionsNetworkState({
      loading: false,
      loaded: false,
      error: null,
    });
  };

  const onInlineEditFocus = (section, itemId) => {
    console.log(`Focus ${section} ${itemId}`);
    const value =
      itemId !== null
        ? currentRecipeRef.current[section].find((it) => it.id === itemId)
            .fullText
        : currentRecipeRef.current[section] || '';
    currentTextRef.current = ['mealTypes', 'utensils'].includes(section)
      ? value
      : value.toString();
    if (section === 'ingredients') {
      const ingredient = currentRecipeRef.current[section].find(
        (it) => it.id === itemId,
      );
      loadSuggestions(ingredient);
    }
    setCurrentlyEditingInline({ section, itemId });
  };

  const onInlineEditChange = (section, itemId, value) => {
    console.log(`Change ${section} ${itemId}`);
    currentTextRef.current = value;
  };

  const onDialogClose = (ev, reason) => {
    if (reason === 'escapeKeyDown') {
      console.log('Dialog escape key down');
      // Prevent dialog from closing, let the contenteditable handle the esc key
      return null;
    }
    console.log(`Dialog close other reason: ${reason}`);
    dismiss();
    return true;
  };

  const onNewImageUrlSet = async (newImageUrl) => {
    dispatch(mealImageUpdatedAction(mealId, newImageUrl));
  };

  const onNewImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(mealId, fileList);
    dispatch(mealImageUpdatedAction(mealId, newImageUrl));
  };

  const onRuleChanged = (itemId, rule) => {
    dispatch(mealIngredientScalingRuleUpdatedAction(mealId, itemId, rule));
  };

  const canScaleIngredients = recipe.ingredients.some(
    (ing) => !!ing.tokens && !!ing.structuredIngredient,
  );

  const onEditScaleToServings = (newScaleToServings) => {
    setScaleToServings(newScaleToServings);
  };

  const onChangeScaleToServings = async () => {
    const scaleToServingsNumber = Number(scaleToServings) || 1;
    if (scaleToServingsNumber !== recipe.servings) {
      const scaleFactor = scaleToServingsNumber / (recipe.servings || 1);
      setSavingInProgress(true);
      try {
        if (isSharedMeal) {
          await dispatch(
            locallyScaleSharedMealAction(
              mealId,
              scaleFactor,
              scaleToServingsNumber,
            ),
          );
        } else {
          await dispatch(
            scaleMealAction(mealId, scaleFactor, scaleToServingsNumber),
          );
        }
      } catch (e) {
        setScaleToServings(recipe.servings);
        throw e;
      } finally {
        setSavingInProgress(false);
      }
    }
  };

  const resetScaleToServings = () => {
    setScaleToServings(recipe.servings);
  };

  const showNutritionMetrics = [
    'calories',
    'fat',
    'carbohydrate',
    'protein',
    'fibre',
  ];

  const onTagsChange = (tags) => {
    console.log(`onTagsChange ${JSON.stringify(tags)}`);
    const nonBlankTags = tags.filter(
      (t) => !!t && JSON.stringify(t).length > 0,
    );
    const currentRecipeTags = recipe.tags || [];
    if (JSON.stringify(currentRecipeTags) !== JSON.stringify(nonBlankTags)) {
      dispatch(mealTagsChangedAction(mealId, nonBlankTags));
      const changedTags = addedItems(nonBlankTags, currentRecipeTags);
      if (changedTags.length > 0) {
        dispatch(tagsUsedAction(changedTags));
      }
    }
  };

  const alwaysShowNutrition = !isCommunityUser;

  return (
    <MealDetailModal
      meal={meal}
      menu={menu}
      isReadOnly={isReadOnly}
      savingInProgress={savingInProgress}
      alwaysShowNutrition={alwaysShowNutrition}
      showNutritionMetrics={showNutritionMetrics}
      derivedNutrition={meal.derivedNutrition}
      canScaleIngredients={canScaleIngredients}
      scaleToServings={scaleToServings}
      currentlyEditingInline={currentlyEditingInline}
      currentTextRef={currentTextRef}
      ingredientSuggestions={ingredientSuggestions}
      ingredientSuggestionsNetworkState={ingredientSuggestionsNetworkState}
      onInlineEditFocus={onInlineEditFocus}
      onInlineEditChange={onInlineEditChange}
      onInlineEditBlur={onInlineEditBlur}
      onAddArraySectionItem={onAddArraySectionItem}
      onRemoveArraySectionItem={onRemoveArraySectionItem}
      onIngredientCheckChange={onIngredientCheckChange}
      onEditScaleToServings={onEditScaleToServings}
      onChangeScaleToServings={onChangeScaleToServings}
      resetScaleToServings={resetScaleToServings}
      onNewImageUrlSet={onNewImageUrlSet}
      onNewImageChosen={onNewImageChosen}
      onRuleChanged={onRuleChanged}
      onAddonChange={(addonName, enabled) => {
        dispatch(setMealAddonEnabledAction(mealId, addonName, enabled));
      }}
      onTagsChange={onTagsChange}
      onDialogClose={onDialogClose}
    />
  );
};

MealDetailContainer.propTypes = {
  parentSmorgBoardId: PropTypes.string.isRequired,
  mealId: PropTypes.string,
  menuId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  // isPreview: PropTypes.bool,
  dismiss: PropTypes.func,
};

MealDetailContainer.defaultProps = {
  mealId: null,
  menuId: null,
  // isPreview: false,
  dismiss: () => {},
};

export default MealDetailContainer;
