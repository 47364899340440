import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, createTheme, useTheme } from '@mui/material';
import MealAdvancedSearchCriteria from '../meal/meal_advanced_search_criteria';
import {
  GRC_RESULTS_GROUP_LABEL,
  useMealSearch,
} from '../common/meal_search_hook';
import RecipesSearchTopResultsView from './recipes_search_top_results_view';
import RecipesSearchGroupResultsView from './recipes_search_group_results_view';
import {
  currentProgrammeEnrollmentSelector,
  sharedProgrammeLocalesSelector,
} from '../../reducers/programmes_reducer';
import PopupTitle from '../common/popup_title';
import { EntryType } from '../../API';
import {
  currentEndUserSpaceSelector,
  userLocaleSelector,
} from '../../reducers/user_reducer';
import { allIngredientNames } from '../../services/ingredients';
import { ensureSharedProgrammeLatestVersionLoadedAction } from '../../action_creators/programmes_action_creators';
import { endUserSharedPogrammeRecipesBoardIDsForSearchSelector } from '../../reducers/recipes_reducer';

const SEARCH_RESULTS_GROUP_LABEL = 'Search results';

const buildSearchCriteriaTheme = (appTheme) => {
  return createTheme(appTheme, {
    palette: {
      primary: {
        main: '#427a46',
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: 'var(--app-primary-font-color)',
            '&:before': {
              borderBottomColor: 'var(--app-primary-font-color) !important',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: 'var(--app-primary-font-color)',
            borderColor: 'var(--app-primary-font-color)',
            '&:hover': {
              color: 'var(--app-primary-font-color)',
            },
          },
        },
      },
    },
  });
};

const RecipesAdvancedSearchView = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const appTheme = useTheme();

  const searchCriteriaTheme = buildSearchCriteriaTheme(appTheme);

  const [route, setRoute] = useState({ page: 'advancedCriteria' });

  const recentlyUsedSearchCriteria = useSelector(
    (state) => state.userProfile?.recentlyUsedSearchCriteria || {},
  );

  const [searchCriteria, setSearchCriteria] = useState(
    recentlyUsedSearchCriteria,
  );

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment?.sharedProgrammeID &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );

  const locales = useSelector((state) =>
    currentProgrammeEnrollment?.sharedProgrammeID
      ? sharedProgrammeLocalesSelector(
          state,
          currentProgrammeEnrollment.sharedProgrammeID,
        )
      : [userLocaleSelector(state)],
  );

  const myRecipesBoardIDs = useSelector((state) =>
    state.recipesBoards.map((board) => board.id).filter((id) => !!id),
  );

  const programmeRecipesBoardsIDs = useSelector(
    endUserSharedPogrammeRecipesBoardIDsForSearchSelector,
  );

  console.log(JSON.stringify({ programmeRecipesBoardsIDs }));

  const groupsConfig = [
    {
      groupLabel: SEARCH_RESULTS_GROUP_LABEL,
      parentIDs: [...myRecipesBoardIDs, ...programmeRecipesBoardsIDs],
    },
  ];
  // Turn off GRC results
  // groupsConfig.push({ groupLabel: GRC_RESULTS_GROUP_LABEL });

  const [results, estimatedCount, isLoading, onLoadMore, ingredientTreeNodes] =
    useMealSearch(searchCriteria, groupsConfig, 'recipes', locales, false);

  const loadedResultsCount = results
    ? results.reduce((acc, group) => acc + group.data.length, 0)
    : 0;

  const moreAvailable = results.some(
    (groupResults) => groupResults.moreAvailable,
  );

  const resultsWithActions = results.map((groupResults) => ({
    ...groupResults,
    entryType:
      groupResults.groupLabel === GRC_RESULTS_GROUP_LABEL
        ? EntryType.GRC_RECIPE
        : EntryType.MEAL,
  }));

  const onDismiss = () => navigate(-1);

  const sharedProgrammeID = sharedProgramme?.id;

  useEffect(() => {
    if (sharedProgrammeID) {
      dispatch(
        ensureSharedProgrammeLatestVersionLoadedAction(sharedProgrammeID),
      );
    }
  }, [dispatch, sharedProgrammeID]);

  const allTags = useSelector((state) =>
    (currentEndUserSpaceSelector(state)?.publishedSearchTags || []).map(
      (t) => t.tag,
    ),
  );

  const onGoToGroup = useCallback((groupLabel) => {
    setRoute({ page: 'groupResults', groupLabel });
  }, []);

  return (
    <div className="recipes-search">
      {route.page === 'advancedCriteria' && (
        <div className="recipes-search-form">
          <PopupTitle
            titleText="Search recipes"
            backButtonEnabled
            onClickBack={onDismiss}
          />
          <div style={{ height: 'calc(var(--app-height) - 106px)' }}>
            <ThemeProvider theme={searchCriteriaTheme}>
              <MealAdvancedSearchCriteria
                searchCriteria={searchCriteria}
                onChangeSearchCriteria={setSearchCriteria}
                totalResultsLoading={isLoading}
                totalResults={estimatedCount}
                loadedResultsCount={loadedResultsCount}
                moreAvailable={moreAvailable}
                onShowResults={() => {
                  setRoute({ page: 'topResults' });
                }}
                onDismiss={onDismiss}
                allIngredients={allIngredientNames(ingredientTreeNodes)}
                allTags={allTags}
              />
            </ThemeProvider>
          </div>
        </div>
      )}
      {route.page === 'topResults' && (
        <div className="recipes-search-results">
          <RecipesSearchTopResultsView
            results={resultsWithActions}
            onGoToGroup={onGoToGroup}
            onLoadMore={onLoadMore}
            onBack={() => {
              setRoute({ page: 'advancedCriteria' });
            }}
            onDismiss={() => navigate(-1)}
            searchCriteria={searchCriteria}
            onChangeSearchCriteria={setSearchCriteria}
          />
        </div>
      )}
      {route.page === 'groupResults' && (
        <div className="recipes-search-results">
          <RecipesSearchGroupResultsView
            groupResults={resultsWithActions.find(
              (r) => r.groupLabel === route.groupLabel,
            )}
            onLoadMore={onLoadMore}
            onBack={() => {
              setRoute({ page: 'topResults' });
            }}
            onDismiss={() => navigate(-1)}
            searchCriteria={searchCriteria}
            onChangeSearchCriteria={setSearchCriteria}
          />
        </div>
      )}
    </div>
  );
};

export default RecipesAdvancedSearchView;
