import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { REMINDER_SCHEDULES } from '../../services/meal_plan_reminders';
import { GEO_REGIONS } from '../../services/geo_regions';
import {
  signOutAction,
  updateUserProfileAction,
} from '../../action_creators/user_action_creators';
import {
  DAY_INDEX_LABELS,
  plannerViewWeekStartDayOrDefault,
} from '../../services/planner';
import DeleteAccountPopup from './delete_account_popup';
import {
  createStripeBillingPortalSessionOperation,
  sendAccountDeletedNotificationOperation,
} from '../../operations/user_profile_operations';
import {
  END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE,
  LOGIN_ROUTE,
  routeAbsoluteUrl,
} from '../../services/routes';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';
import {
  currentEndUserSpaceIDSelector,
  currentSpaceMembershipIDSelector,
  currentSpaceMembershipSelector,
  isUserSpaceMembershipDefaultSpaceSelector,
} from '../../reducers/user_reducer';
import { SpaceMembershipState } from '../../API';
import { linkWithQueryParams } from '../../services/urls';
import { MINIMUM_PASSWORD_LENGTH } from '../../services/users';

const billingPortalReturnUrl = routeAbsoluteUrl(
  END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE,
);

const UserProfileEndUserDesktopView = () => {
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.userProfile);

  const spaceID = useSelector(currentEndUserSpaceIDSelector);

  const [userId, setUserId] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [cleartextPassword, setCleartextPassword] = useState('');
  const [geoRegion, setGeoRegion] = useState(userProfile.geoRegion);
  const [plannerViewWeekStartDay, setPlannerViewWeekStartDay] = useState(
    plannerViewWeekStartDayOrDefault(userProfile.plannerViewWeekStartDay),
  );
  const [mealPlanReminderSchedule, setMealPlanReminderSchedule] = useState(
    userProfile.mealPlanReminderSchedule,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBillingPortalLoading, setIsBillingPortalLoading] = useState(false);

  const [deleteAccountPopupVisible, setDeleteAccountPopupVisible] =
    useState(false);

  const dispatch = useDispatch();

  const skipNonEssentialFields = isEmbeddedInSmorgCompanion();

  useEffect(() => {
    console.log('Loading profile data');
    Auth.currentUserInfo().then((currentUserInfo) => {
      setUserId(currentUserInfo.username);
      setGivenName(currentUserInfo.attributes.given_name);
      setFamilyName(currentUserInfo.attributes.family_name);
      setEmail(currentUserInfo.attributes.email);
    });
  }, []);

  const handleSave = async () => {
    if (!givenName) {
      setErrorMessage('Please fill in your first name');
      return;
    }
    if (!familyName) {
      setErrorMessage('Please fill in your surname');
      return;
    }
    if (
      (cleartextPassword && !oldPassword) ||
      (!cleartextPassword && oldPassword)
    ) {
      setErrorMessage(
        'To change your password, you need to type your existing password as well.',
      );
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true);
      await dispatch(
        updateUserProfileAction(
          email,
          givenName,
          familyName,
          userProfile.consentToProductComms,
          oldPassword,
          cleartextPassword,
          geoRegion,
          mealPlanReminderSchedule,
          plannerViewWeekStartDay,
        ),
      );
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
      if (
        (err.code === 'InvalidParameterException' &&
          (err.message || '').match(
            /'password' failed to satisfy constraint/,
          )) ||
        err.code === 'InvalidPasswordException'
      ) {
        setErrorMessage(
          `Your password must be at least ${MINIMUM_PASSWORD_LENGTH} characters`,
        );
      } else if (err.code === 'NotAuthorizedException') {
        setErrorMessage(
          'You have typed your current password incorrectly, please try again',
        );
      } else if (err.code === 'LimitExceededException') {
        setErrorMessage('Please try again after a while');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
    setErrorMessage('');
  };

  const handleClickDeleteAccount = () => {
    setDeleteAccountPopupVisible(true);
  };

  const handleDeleteAccount = async () => {
    if (!userId || !email) {
      console.warn(
        'userId or email are not available, will not initiate account deletion.',
      );
      return;
    }
    try {
      await sendAccountDeletedNotificationOperation(userId, email);
      const result = await Auth.deleteUser();
      console.log(result);
      dispatch(signOutAction());
      navigate(
        linkWithQueryParams(LOGIN_ROUTE, {
          intoSpace: spaceID,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const isPaymentRequired = !useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const currentSpaceMembershipTierID = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.membershipTierID,
  );

  const currentSpaceMembershipState = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.state,
  );

  const currentSpaceMembershipExpirationDate = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.expiresAt,
  );

  const currentSpaceMembershipTierTitle = useSelector((state) => {
    const currentmtid = currentSpaceMembershipSelector(state)?.membershipTierID;
    return state.membershipTiers.find((mt) => mt.id === currentmtid)?.title;
  });

  const spaceMembershipID = useSelector(currentSpaceMembershipIDSelector);

  const hasMembershipTier = !!currentSpaceMembershipTierID;

  const handleClickManageSubscription = async () => {
    setIsBillingPortalLoading(true);
    try {
      const session = await createStripeBillingPortalSessionOperation(
        spaceMembershipID,
        billingPortalReturnUrl,
      );
      console.log(JSON.stringify({ session }));
      if (session?.url) {
        window.open(session.url, '_blank');
      }
    } finally {
      setIsBillingPortalLoading(false);
    }
  };

  return (
    <div className="userProfile light-background">
      <div className="banner">Manage your personal information</div>
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={12}>
          <h5>Your name</h5>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={givenName}
            onChange={(ev) => {
              setGivenName(ev.target.value);
              afterChange();
            }}
            autoFocus
            margin="dense"
            label="First name *"
            autoComplete="given-name"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={familyName}
            onChange={(ev) => {
              setFamilyName(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="Surname *"
            autoComplete="family-name"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Your email address</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={email}
            margin="dense"
            label="Email address *"
            type="email"
            fullWidth
            size="small"
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Your password</h5>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={oldPassword}
            onChange={(ev) => {
              setOldPassword(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="Current password *"
            type="password"
            // Disables annoying Chrome autofill
            autoComplete="new-password"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={cleartextPassword}
            onChange={(ev) => {
              setCleartextPassword(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="New password *"
            type="password"
            autoComplete="new-password"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        {!skipNonEssentialFields && (
          <>
            <Grid item xs={12}>
              <h5>Your location</h5>
            </Grid>
            <Grid item xs={12}>
              <Select
                variant="standard"
                fullWidth
                displayEmpty
                value={geoRegion}
                onChange={(ev) => {
                  const newRegion = ev.target.value;
                  setGeoRegion(newRegion);
                  afterChange();
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem disabled value="">
                  <em>Select a location</em>
                </MenuItem>
                {GEO_REGIONS.map((region) => (
                  <MenuItem key={region.name} value={region.name}>
                    {region.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <h5>Your planning week starts on</h5>
        </Grid>
        <Grid item xs={12}>
          <Select
            variant="standard"
            fullWidth
            displayEmpty
            value={plannerViewWeekStartDay}
            onChange={(ev) => {
              const newStartDay = parseInt(ev.target.value, 10);
              setPlannerViewWeekStartDay(newStartDay);
              afterChange();
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            {DAY_INDEX_LABELS.map((label, dayIndex) => (
              <MenuItem key={label} value={dayIndex}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {!skipNonEssentialFields && (
          <>
            <Grid item xs={12}>
              <h5>Meal plan reminders</h5>
            </Grid>
            <Grid item xs={12}>
              <Select
                variant="standard"
                fullWidth
                displayEmpty
                value={mealPlanReminderSchedule}
                onChange={(ev) => {
                  const newScheduleName = ev.target.value;
                  setMealPlanReminderSchedule(newScheduleName);
                  afterChange();
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem disabled value="">
                  <em>Select a schedule</em>
                </MenuItem>
                {REMINDER_SCHEDULES.map((schedule) => (
                  <MenuItem key={schedule.name} value={schedule.name}>
                    {schedule.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
        {isPaymentRequired && (
          <>
            <Grid item xs={12}>
              <h5>Subscription tier</h5>
            </Grid>
            <Grid item xs={12}>
              {!hasMembershipTier && 'No subscription yet'}
              {hasMembershipTier &&
                currentSpaceMembershipState === SpaceMembershipState.ACTIVE && (
                  <div style={{ fontSize: '16px' }}>
                    {currentSpaceMembershipTierTitle} (expires{' '}
                    {dayjs(currentSpaceMembershipExpirationDate).format(
                      'DD MMM YYYY',
                    )}
                    )
                    <br />
                    {isBillingPortalLoading ? (
                      'Please wait...'
                    ) : (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleClickManageSubscription();
                          }}>
                          Manage subscription
                        </a>
                      </>
                    )}
                  </div>
                )}
            </Grid>
            {hasMembershipTier &&
              currentSpaceMembershipState ===
                SpaceMembershipState.CANCELLED && (
                <div style={{ fontSize: '16px' }}>
                  {currentSpaceMembershipTierTitle} (expires{' '}
                  {dayjs(currentSpaceMembershipExpirationDate).format(
                    'DD MMM YYYY',
                  )}
                  )
                </div>
              )}
          </>
        )}
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          {errorMessage && (
            <div style={{ paddingTop: '5px', color: 'red' }}>
              {errorMessage}
            </div>
          )}
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate(-1)}>
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isDirty || isLoading}>
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            style={{ color: 'red', textDecoration: 'underline' }}
            onClick={(ev) => {
              ev.preventDefault();
              handleClickDeleteAccount();
            }}>
            Delete account
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            fontSize: '12px',
            textAlign: 'center',
            marginBottom: '20px',
          }}>
          <Grid container>
            <Grid item xs={6}>
              <a href="https://www.smorg.io/terms" target="_new">
                Terms & Conditions
              </a>
            </Grid>
            <Grid item xs={6}>
              <a href="https://www.smorg.io/privacy" target="_new">
                Privacy Policy
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {deleteAccountPopupVisible && (
        <DeleteAccountPopup
          onDelete={handleDeleteAccount}
          onDismiss={() => setDeleteAccountPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default UserProfileEndUserDesktopView;
